import { Box } from "@mui/material";
import CalcOption from "./CalcOption";
import {  useMemo, useState } from "react";
import { HeaderOptions } from "../../../../types/budget/budget";

type Props = {
  onActiveOptionChange: (type: HeaderOptions) => void
}

export default function LeftSideBarHeader({onActiveOptionChange} : Props) {
  const [activeOption, setActiveOption] = useState<HeaderOptions>("A");

  useMemo(()=> {
    onActiveOptionChange(activeOption)
  }, [activeOption])
  const optionsArr: HeaderOptions[] = ["A", "B", "C", "D", "E", "budgetTotals"]
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"space-evenly"}>
      {optionsArr.map((el) => {
        return (
          <CalcOption
            type={el}
            setIsActive={(type) => setActiveOption(type)}
            isActive={activeOption === el}
            key={el}
          />
        );
      })}
    </Box>
  );
}
