import { Box } from "@mui/material";
import { formatCurrency } from "../../../../utils/formatters";

type ItemTotalsProps = {
    total: number;
    savings?: number;
  };
 export const ItemTotalsSummary = ({ total, savings }: ItemTotalsProps) => {
    const savingsColor = !!savings && savings > 0 ? "#EA5455" : "#28C76F";
    const savingsSign = !!savings && savings > 0 ? "+" : "-";
    return (
      <Box
        sx={{ fontWeight: "bold" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box>{formatCurrency(total)}</Box>
        {savings && (
          <Box sx={{ color: savingsColor, ml: 1 }}>
          
           {savingsSign} {formatCurrency(Math.abs(savings))}
          </Box>
        )}
      </Box>
    );
  };