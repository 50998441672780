import { Box, Divider } from "@mui/material";
import TotalAccordion from "./TotalAccordion";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import { NFMMOption } from "../../../../types/budget/budget";

interface BudgetTotalsProps {}

export default function BudgetTotals({}: BudgetTotalsProps) {
  const { activeBudgetIndex, activeBudget } = useBudget();
  const optionsArr: NFMMOption[] = ["A", "B", "C", "D", "E"];

  return (
    <Box width={"100%"} gap={1}>
      <Box pb={2}>
        <span style={{ fontSize: "20px", fontWeight: "semi-bold" }}>
          Totais - Orçamento {activeBudgetIndex + 1}
        </span>
      </Box>
      {optionsArr.map((el) => {
        const services = activeBudget.budgetServices.filter(
          (service) => service.calcOption === el
        );
        if (services.length < 1) return <></>;
        return (
          <TotalAccordion
            type={el}
            totalPerCalcOption={
              activeBudget.budgetValuesTotal.byCalculationType[el]
            }
            services={services}
          />
        );
      })}
      {/* <TotalAccordion />
      <TotalAccordion />
      <TotalAccordion /> */}
      <Divider />
      <Box my={2} gap={2}>
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          fontWeight={"bold"}
        >
          <Box>Taxas</Box>
          <Box>R$ 00.000,00</Box>
        </Box> */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          fontWeight={"bold"}
          my={1}
        >
          <Box>Total</Box>
          <Box>R$ 00.000,00</Box>
        </Box>
      </Box>
    </Box>
  );
}
