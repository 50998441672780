import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";

import { createOptionsFromEnum } from "../../../../utils/formatters";
import { BudgetItemTypeEnum } from "../../../../types/budget/budget";
import YesOrNoSwitch from "../../../UI/YesOrNoSwitch";
import { VerticalTab } from "../../../VerticalTabs";
import { BudgetIcons } from "../../../../assets/styled-icons/budget";
import { RxReset } from "react-icons/rx";
import { toast } from "react-toastify";

interface RequesterModalProps {
  isOpen: boolean;
  area: string;
  label: string;
  onClose: () => void;
}

const INITIAL_STATE = {
  ACCOMMODATIONS: undefined,
  ROOMS: undefined,
  AEB: undefined,
  EQUIPMENTS: undefined,
  TRANSLATIONS: undefined,
  SUPPORT: undefined,
  SUBSCRIPTION: undefined,
  RSVP: undefined,
  AIR: undefined,
  TRANSFER: undefined,
  COMMUNICATIONS: undefined,
  SEVERAL: undefined,
};

const itemTypeOption = createOptionsFromEnum(BudgetItemTypeEnum);

const ImportModal = ({ isOpen, onClose, label, area }: RequesterModalProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";

  const [serviceTypesList, setServiceTypesList] = useState(
    INITIAL_STATE as any
  );
  const options = itemTypeOption.map((el: any, index: number) => {
    if (index === itemTypeOption.length - 1)
      return {
        title: el.label,
        value: el.value,
        description: "",
        icon: BudgetIcons[el.value],
      };
    return {
      title: el.label,
      value: el.value,
      description: "",
      icon: BudgetIcons[el.value],
    };
  });
  const halfLength = Math.ceil(options.length / 2);
  const optionsLeft = options.slice(0, halfLength);
  const optionsRight = options.slice(halfLength, options.length);
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={"sm"}>
      <DialogTitle sx={{ m: 0, px: 4, paddingTop: 3, color: "#1361A4" }}>
        Importar da logística
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <DialogContent sx={{ px: 4, marginTop: -4 }}>
        <Box display={"flex"} flexDirection={"column"}>
          <Box
            width={"30px"}
            borderRadius={2}
            display={"flex"}
            justifyContent={"center"}
            p={0.8}
            border={"1px solid #1361A4"}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setServiceTypesList(INITIAL_STATE)}
          >
            <RxReset color="#1361A4" />
          </Box>
          <VerticalTab.Root noBorder={true}>
            <Box display={"flex"} gap={1} width={"100%"}>
              <Box>
                {optionsLeft.map((el: any, index: any) => (
                  <Box gap={1} mb={2}>
                    <VerticalTab.Item key={index}>
                      <VerticalTab.Icon
                        icon={el.icon}
                        isActive={false}
                        color={
                          serviceTypesList[el.value] === true
                            ? "#1361A4"
                            : serviceTypesList[el.value] === false
                            ? "#EFDBDB"
                            : undefined
                        }
                      />
                      <VerticalTab.Content
                        title={el.title}
                        description={el.description}
                      />
                      <Box display="flex" flex={1} justifyContent={"flex-end"}>
                        <YesOrNoSwitch
                          type={el.value}
                          value={serviceTypesList[el.value]}
                          setValue={(type: string, newValue: boolean) =>
                            setServiceTypesList({
                              ...serviceTypesList,
                              [type]: newValue,
                            })
                          }
                        />
                      </Box>
                    </VerticalTab.Item>
                  </Box>
                ))}
              </Box>
              <Box>
                {optionsRight.map((el: any, index: any) => (
                  <Box gap={1} mb={2}>
                    <VerticalTab.Item key={index}>
                      <VerticalTab.Icon
                        icon={el.icon}
                        isActive={false}
                        color={
                          serviceTypesList[el.value] === true
                            ? "#1361A4"
                            : serviceTypesList[el.value] === false
                            ? "#EFDBDB"
                            : undefined
                        }
                      />
                      <VerticalTab.Content
                        title={el.title}
                        description={el.description}
                      />
                      <Box display="flex" flex={1} justifyContent={"flex-end"}>
                        <YesOrNoSwitch
                          type={el.value}
                          value={serviceTypesList[el.value]}
                          setValue={(type: string, newValue: boolean) =>
                            setServiceTypesList({
                              ...serviceTypesList,
                              [type]: newValue,
                            })
                          }
                        />
                      </Box>
                    </VerticalTab.Item>
                  </Box>
                ))}
              </Box>
            </Box>
          </VerticalTab.Root>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          gap={2}
          display={"flex"}
          justifyContent={"space-evenly"}
          mr={3}
          mb={1}
        >
          <Button
            variant="outlined"
            sx={{
              fontSize: "14px",
              mt: 2,
              px: 2,
            }}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              fontSize: "14px",
              mt: 2,
              px: 2,
            }}
            onClick={() => {
              toast.warn("Não implementado ainda");
            }}
          >
            Importar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ImportModal;
