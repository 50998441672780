import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  Button,
} from "@mui/material";
import {  useEffect, useState } from "react";
import { InputDiv, SummaryDiv, RadioDiv, StyledBox } from "../styles";

import { Input } from "../../../Input";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { AerialFieldType, FlightClassEnum, MandatoryDocsEnum, RsvpType } from "../../../../types/rsvp/index";
import { FormInputMultiCheckbox } from "../PreCongress/FormMultipleCheckBox";
import CurrencyInputForm from "../../../CurrencyInputForm";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Aerial({onSubmitNav, isDisabled}: Props) {
  const documentsOptions = (Object.keys(MandatoryDocsEnum) as Array<keyof typeof MandatoryDocsEnum>).map(el => {
    return { label: MandatoryDocsEnum[el], value: el }
  })
  const flightClassOptions = (Object.keys(FlightClassEnum) as Array<keyof typeof FlightClassEnum>).map(el => {
    return { label: FlightClassEnum[el], value: el }
  })
  const { rsvpId, updateAerial, createRsvp, aerial } = useRsvp()
  const obj: AerialFieldType = {
    paymentMethod: "",
    flightClasses: [],
    aerialDateChanges: "",
    dateChangesOptions: "",
    dateChangeDeadline: "",
    ticketChangeAfterEmission: "",
    mandatoryDocs: [],
    paymentMethodObs: "",
    maxValueObs: 0,
    aerialDateChangesObs: "",
    ticketChangeObs: "",
    mandatoryDocsObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset, control } = methods
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [observation, setObservation] = useState('');

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSwitchOn(checked);
  };

const handleObservationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setObservation(event.target.value);
};

  function onSubmit(form: AerialFieldType) {
    rsvpId ? updateAerial(form) : createRsvp({ aerial: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (aerial) {
      reset(aerial);
      setFlightClassState(aerial.flightClasses)
    }
  }, [aerial]);
  const [flightClassState, setFlightClassState] = useState([] as string[])
  const [mandatoryDocsState, setMandatoryDocsState] = useState([] as string[])


  return (    <FormProvider {...methods}>
    <StyledBox onSubmit={handleSubmit(onSubmit)}>
      <h3>5.Áereo/Seguro viagem</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Forma de pagamento</strong>
          <span>
            Detalhes do pagamento e emissões. especificar em 8.Outros os dados
            de emissão, caso haja
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup

              value={watch("paymentMethod")}
              onChange={(e) => setValue("paymentMethod", e.target.value)}
            >
              <FormControlLabel
                value="MM_BILLING"
                control={<Radio />}
                label="Faturamento MM"
              />
              <FormControlLabel
                value="CLIENT_CARD"
                control={<Radio />}
                label="Cartão do cliente"
              />
              <FormControlLabel
                value="NOT_PARTIAL"
                control={<Radio />}
                label="Não parcial"
              />

            </RadioGroup>
          </FormControl>
          <Input
            label="Outros"
            {...register('paymentMethodObs')}
            multiline
            size="small"
          />

          <CurrencyInputForm
            label="Valor Máximo permitido por emissão"
            name='maxValueObs'
          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>
            Especificar a(s) classes na qual as passagens aéreas devem ser
            emitidas
          </strong>
          <span>Colega não incluso na lista</span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
          <FormGroup>
              <FormLabel>Classes de vôo</FormLabel>
              <FormInputMultiCheckbox
                name={"flightClass"}
                control={control} label={'label'}
                options={flightClassOptions}
                setValue={setValue}
                previousSelected={flightClassState}
                optionSelectAll={true}
                setControlledState={setFlightClassState}
              />
            </FormGroup>
          </FormControl>
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Solicitação do convidado para alteração de áereo</strong>
          <span>
            Referente a responsabilidades e permissões de alteração de última
            hora.Atenção com o convidado ao não permitir alguma alteração. (Em
            caso de ruido comunicar coordenador/cliente)
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("aerialDateChanges")}
              onChange={(e) => setValue("aerialDateChanges", e.target.value)}
            >
              <FormControlLabel
                value="NO_DATE_CHANGES_ALLOWED"
                control={<Radio />}
                label="Não são permitidas alterações de datas, os voos devem ser emitidos nas datas sugeridas."
              />
              <FormControlLabel
                value="NO_APPROVAL_REQUIRED"
                control={<Radio />}
                label="Aprovação não é necessária pelo coordenador/cliente"
              />
              <FormGroup row>

              </FormGroup>
            </RadioGroup>
            <RadioGroup
              value={watch("dateChangeDeadline")}
              onChange={(e) => setValue("dateChangeDeadline", e.target.value)}
            >
              <FormControlLabel
                value="HOURS_24_BEFORE_AND_AFTER"
                control={<Radio />}
                label="24h antes e depois"
              />
              <FormControlLabel
                value="HOURS_48_BEFORE_AND_AFTER"
                control={<Radio />}
                label="48h antes e depois"
              />
              {/* <FormControlLabel
                value="EXTRA_DAY_BY_GUEST"
                control={<Radio />}
                label="Diária extra por conta do convidado"
              />
              <FormControlLabel
                value="EXTRA_DAY_REQUESTED_WITHIN_TIME"
                control={<Radio />}
                label="Diária extra solicitada dentro do horário"
              /> */}
              <FormControlLabel
                value="CASE_BY_CASE_WITH_COORDINATOR_OR_CLIENT"
                control={<Radio />}
                label="Analisar caso a caso junto ao coordenador/cliente"
              />

            </RadioGroup>
          </FormControl>
          <Input
            label="Outros"
            {...register('aerialDateChangesObs')}
            multiline
            size="small"
          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>É intermediado pela MM?</strong>
          <span>Alterações particulares ou intermediações de alterações </span>
        </SummaryDiv>
        <RadioDiv>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
              label={isSwitchOn ? 'Sim' : 'Não'}
            />
            {isSwitchOn && (
              <Input
                value={observation}
                onChange={handleObservationChange}
                placeholder="Observações"
                multiline
                size="small"
                style={{ width: '158%' }}
              />
            )}
          </div>
        </RadioDiv>
      </InputDiv>

      <InputDiv>
        <SummaryDiv>
          <strong>Documentos/Vacinas obrigatórios no destino</strong>
          <span>
            Pode se inserir as informações e orientações com o modelo carta
            convite enviado
          </span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <FormGroup>
              <FormLabel>Documentos necessários</FormLabel>
              <FormInputMultiCheckbox
                name={"mandatoryDocs"}
                control={control} label={'label'}
                options={documentsOptions}
                setValue={setValue}
                previousSelected={mandatoryDocsState}
                setControlledState={setMandatoryDocsState}
                optionSelectAll={true}
              />
            </FormGroup>
          </FormControl>
          <Input
            label="Outros"
            {...register('mandatoryDocsObs')}
            multiline
            size="small"
          />
        </RadioDiv>
      </InputDiv>

      <Button
        disabled={isDisabled}
        variant="contained"
        
        style={{ marginLeft: "70%" }}
        type="submit"
      >
        Salvar
      </Button>
    </StyledBox>
    </FormProvider>
  );
}
