import { Box } from "@mui/material";
import React from "react";
import { LabeledInput } from "../../../UI/LabeledInput";
import { DefaultTax, typeTax } from "../../../../types/budget/service-item";
import ChangelingMonetaryInput from "../../../UI/ChangelingMonetaryInput";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";

type Props = {
  title: string;
  previousState: DefaultTax | undefined;
  comparisonState: DefaultTax | undefined;
  type: keyof typeof typeTax;
  calculusOptions: keyValueObj[];
  triggerCalculus(type: string, taxParameters: DefaultTax): void;
  disableChanges: boolean;
  selectedCurrencyCode: string | undefined;
};
type keyValueObj = {
  value: string;
  label: string;
};

const TaxWithConvertedValue: React.FC<Props> = ({
  title,
  previousState,
  comparisonState,
  type,
  calculusOptions,
  triggerCalculus,
  selectedCurrencyCode,
  disableChanges,
}: Props) => {
  const {isLoadingCalcs, negotiationVersion} = useBudget()

  const handleUpdateCalcValues = (
    type: string,
    value: number,
    calcType: string
  ) => {
    const newTaxState = {
      ...previousState,
      typeTax: calcType,
      currencyValue: value,
    };
    triggerCalculus(type, {...newTaxState, value: newTaxState.value || 0, valueConverted: newTaxState.valueConverted || 0});
  };

  function calcResultInputValue(currencyCode: any, referenceValues: DefaultTax | undefined): string {
    const convertedPart = !!currencyCode
      ? `${currencyCode} ${referenceValues?.currencyValue || 0} / `
      : "";
    return type === "ISS"
      ? `R$${referenceValues?.issTariffTaxCalculated || 0}`
      : convertedPart + `R$ ${referenceValues?.valueConverted || 0}`;
  }
  function defineHelperText(): any {
    if(negotiationVersion < 1) return undefined
    if(type === 'ISS') {
      return comparisonState?.iss + '%'
    } else {
      if(comparisonState?.typeTax === 'PERCENTAGE') return comparisonState?.value + ' %'
      else return comparisonState?.value
    }

  }
  return (
    <Box display={"flex"} gap={3}>
      <ChangelingMonetaryInput
        prevValue={{
          calcType: type === "ISS" ? "PERCENTAGE" : previousState?.typeTax || '',
          value:
            type === "ISS" 
              ? previousState?.iss || 0
              : previousState?.value || 0 * 100,
        }}
        label={title}
        type={type}
        calculusOptions={calculusOptions}
        updateCalcValues={handleUpdateCalcValues}
        disabled={disableChanges || isLoadingCalcs}
        helperText={defineHelperText()}
      />

      <LabeledInput
        disabled
        label={<span>Total/Total convertido</span>}
        value={calcResultInputValue(selectedCurrencyCode, previousState)}
        helperText={calcResultInputValue(selectedCurrencyCode, comparisonState)}
      />
    </Box>
  );
};

export default TaxWithConvertedValue;
