
import { api } from "./apiClient";
export default class FileUploadService {
  static async getFiles(params?: any): Promise<any[]> {
    return api.get("/file-upload", { params }).then((response) => response.data);
  }

  static async create(data: any): Promise<any> {
    return api.post(`/file-upload/image`, data, {
      headers:
       {
        "Content-Type": "multipart/form-data",
       }
    }).then((response) => response.data);
  }

  static async update(id: string, data: any): Promise<any> {
    return api
      .put(`/file-upload/image/${id}`, data)
      .then((response) => response.data);
  }
  static async delete(id: string): Promise<any> {
    return api.delete(`/client/delete/${id}`).then((response) => response.data);
  }
  
}
