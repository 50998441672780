import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, IconButton, MenuItem, Tooltip } from "@mui/material";
import { differenceInDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useProject } from "../../../contexts/project.context";
import { CepData } from "../../../types";
import {
  InvoiceTypeEnum,
  PaymentMethodEnum,
} from "../../../types/project/enums";
import { createOptionsFromEnum } from "../../../utils/formatters";
import {
  projectTypeDescription,
  projectTypes,
} from "../../../utils/opcoesBriefingInicial";
import CurrencyInputForm from "../../CurrencyInputForm";
import DatePickerForm from "../../DatePickerForm";
import { Input } from "../../Input";
import { RadioGroup } from "../../UI/RadioGroup";
import Select from "../../UI/Select";
import YesOrNoSwitch from "../../UI/YesOrNoSwitch";
import TabContent from "../components/TabContent";
import { useQueryClient } from "react-query";

const eventTypes = projectTypes.map((type) => ({ value: type, label: type }));

const eventFormats = [
  { label: "Presencial", value: "presential" },
  { label: "Híbrido", value: "hibrid" },
  { label: "Virtual", value: "online" },
];

const billingOptions = createOptionsFromEnum(InvoiceTypeEnum);
const paymentOptions = createOptionsFromEnum(PaymentMethodEnum);

const ProjectEvent = () => {
  const { event, updateEvent, project, clearProject } = useProject();
  const history = useHistory();
  const queryClient = useQueryClient();

  function isRequiredDueToAprovedSTatus(message: string) {
    return project?.status.status === "APPROVED"
      ? yup.string().required(message)
      : yup.string().nullable();
  }

  const schema = yup
    .object({
      name: yup
        .string()
        .max(99, "Nome do evento deve ter no máximo 99 caracteres")
        .required("Nome do evento é obrigatório"),
      type: yup.string().required("Tipo de evento é obrigatório"),
      local: isRequiredDueToAprovedSTatus("Local é obrigatório"),
      addressFixed: yup.boolean().required("Endereço fixo é obrigatório"),
      eventFormat: yup.string().required("Formato de evento é obrigatório"),
      metrics: yup.string(),
      zip_code: isRequiredDueToAprovedSTatus("CEP é obrigatório"),
      city: isRequiredDueToAprovedSTatus("Cidade é obrigatório"),
      number: yup.string().nullable(),
      state: isRequiredDueToAprovedSTatus("Estado é obrigatório"),
      country: isRequiredDueToAprovedSTatus("Páis é obrigatório"),
      complement: isRequiredDueToAprovedSTatus("Logradouro é obrigatório"),
      complement2: isRequiredDueToAprovedSTatus("Complemento é obrigatório"),
      address: yup.string(),
      neighborhood: isRequiredDueToAprovedSTatus("Bairro é obrigatório"),
      street_type: yup.string(),
      eventProfile: yup.string(),
      audience: yup.string(),
      numberOfPeople: yup
        .number()
        .typeError("Número de pessoas é obrigatório")
        .min(1, "Número de pessoas é obrigatório")
        .required("Número de pessoas é obrigatório"),
      startDate: yup
        .date()
        .typeError("Data inválida")
        .required("Data início é obrigatório")
        .test(
          "is-after-2015",
          "Data não pode ser anterior a 2015",
          (value: Date | undefined): boolean => {
            if (!value) return true;
            const selectedDate = new Date(value);
            const minDate = new Date(2015, 0, 1);
            return selectedDate >= minDate;
          }
        ),
      endDate: yup
        .date()
        .typeError("Data inválida")
        .required("Data fim é obrigatória")
        .test(
          "is-after-2015",
          "Data não pode ser anterior a 2015",
          (value: Date | undefined): boolean => {
            if (!value) return true;
            const selectedDate = new Date(value);
            const minDate = new Date(2015, 0, 1);
            return selectedDate >= minDate;
          }
        )
        .min(
          yup.ref("startDate"),
          "Data fim não pode ser anterior à data inicial"
        ),
      sequenceDays: yup.boolean().required("Dias corridos é obrigatório"),
      duration: yup.number(),
      sameTimeEveryDay: yup
        .boolean()
        .required("Mesmo horário início e fim todos os dias é obrigatório"),
      objective: yup.string(),
      observationsEvent: yup.string(),
      customerChallenge: yup.string(),
      isInternational: yup.boolean().required("Internacional é obrigatório"),
      hasCompetitor: yup.boolean().required("Concorrência é obrigatório"),
      numberOfCompetitors: yup.number().nullable(),
      briefingStartDate: yup
        .date()
        .typeError("Data inválida")
        .required("Inicio Briefing é obrigatório")
        .test(
          "is-after-2015",
          "Data não pode ser anterior a 2015",
          function (value: Date | undefined): boolean {
            if (!value) return true;
            const selectedDate = new Date(value);
            const minDate = new Date(2015, 0, 1);
            return selectedDate >= minDate;
          }
        ),
      sendDate: yup
        .date()
        .typeError("Data inválida")
        .required("Envio de Briefing é obrigatório")
        .test(
          "is-after-2015",
          "Data não pode ser anterior a 2015",
          function (value: Date | undefined): boolean {
            if (!value) return true;
            const selectedDate = new Date(value);
            const minDate = new Date(2015, 0, 1);
            return selectedDate >= minDate;
          }
        ),
      presentationDate: yup
        .date()
        .typeError("Data inválida")
        // .nullable()
        .required("Data de Apresentação é Obrigatória")
        .test(
          "is-after-2015",
          "Data não pode ser anterior a 2015",
          function (value: Date | undefined): boolean {
            if (!value) return true;
            const selectedDate = new Date(value);
            const minDate = new Date(2015, 0, 1);
            return selectedDate >= minDate;
          }
        ),
      observationsBriefing: yup.string(),
      pfzCode: yup.string(),
      mappCode: yup.string(),
      customerBudget: yup.number(),
      paymentTerm: yup.string(),
      acceptAdvances: yup.boolean().required("Faz adiantamento é obrigatório"),
      advanceObservations: yup.string(),
      hasDrawnRisk: yup.boolean().required("Possui risco sacado é obrigatório"),
      monthlyTax: yup.number(),
      drawnRiskObservations: yup.string(),
      makesDirectPayments: yup
        .boolean()
        .required("Paga fornecedores diretamente é obrigatório"),
      directPaymentsObservations: yup.string(),
      fee: yup.string(),
      paymentMethod: yup.string(),
      paymentMethodObservations: yup.string(),
      billingMethod: yup.string(),
      hourInit: yup.string(),
      hourEnd: yup.string(),
      schedulleObservations: yup.string(),
    })
    .required();

  const methods = useForm({
    defaultValues: {
      name: "",
      type: "",
      local: "",
      addressFixed: undefined,
      eventFormat: "presential",
      metrics: "",
      zip_code: "",
      number: "",
      country: "",
      state: "",
      city: "",
      complement: "",
      neighborhood: "",
      eventProfile: "",
      audience: "",
      numberOfPeople: 0,
      startDate: undefined,
      endDate: undefined,
      sequenceDays: undefined,
      duration: 0,
      sameTimeEveryDay: undefined,
      objective: "",
      observationsEvent: "",
      customerChallenge: "",
      isInternational: undefined,
      hasCompetitor: undefined,
      numberOfCompetitors: 0,
      briefingStartDate: undefined,
      sendDate: undefined,
      presentationDate: undefined,
      observationsBriefing: "",
      pfzCode: "",
      mappCode: "",
      customerBudget: 0,
      paymentTerm: "",
      acceptAdvances: undefined,
      advanceObservations: "",
      hasDrawnRisk: undefined,
      monthlyTax: 0,
      drawnRiskObservations: "",
      makesDirectPayments: undefined,
      directPaymentsObservations: "",
      fee: "",
      paymentMethod: "",
      billingMethod: "",
      paymentMethodObservations: "",
      hourInit: "",
      hourEnd: "",
      schedulleObservations: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        if (value.message) toast.error(value.message);
      }
    }
  }, [errors]);
  const [cepData, setCepData] = useState<CepData | null>(null);

  const fetchCepData = async (cep: any) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      setCepData(data);
    } catch (error) {
      console.error("Error fetching CEP data:", error);
    }
  };

  useEffect(() => {
    if (cepData) {
      setValue("complement", cepData.logradouro || "");
      setValue("neighborhood", cepData.bairro || "");
      setValue("city", cepData.localidade || "");
      setValue("state", cepData.uf || "");
    }
  }, [cepData, setValue]);

  const isInternationWatch = watch("isInternational");
  const hasCompetitorWatch = watch("hasCompetitor");
  const addressFixedWatch = watch("addressFixed");
  const sequenceDaysWatch = watch("sequenceDays");
  const sameTimeEveryDayWatch = watch("sameTimeEveryDay");
  const startDateWatch = watch("startDate");
  const endDateWatch = watch("endDate");
  const typeWatch = watch("type");
  const acceptAdvancesWatch = watch("acceptAdvances");
  const hasDrawnRiskWatch = watch("hasDrawnRisk");
  const makesDirectPaymentsWatch = watch("makesDirectPayments");

  useEffect(() => {
    if (startDateWatch && endDateWatch) {
      const daysDifference = differenceInDays(endDateWatch, startDateWatch);
      // if(daysDifference < 0) {
      //    toast.error('Data fim não pode ser anterior à data inicio')
      //    setValue('endDate', startDateWatch)
      //    return
      // }
      const duration = daysDifference + 1;
      setValue("duration", duration);
    }
  }, [startDateWatch, endDateWatch, setValue]);

  async function onSubmit(form: any) {
    await updateEvent(form);
    if (!project?._id) {
      await queryClient.resetQueries(["projects"]);
      clearProject();
      history.push("/projetos");
      toast.success("Projeto criado com sucesso");
    }
  }

  function handleBlur() {
    const dailyCalc = differenceInDays(endDateWatch, startDateWatch);
    if (dailyCalc < 0) {
      setValue("endDate", startDateWatch);
      return toast.error("Data fim não pode ser anterior à data inicio");
    }
  }

  useEffect(() => {
    if (event) {
      reset(event);
    }
  }, [reset, event]);

  return (
    <FormProvider {...methods}>
      <TabContent onSubmit={handleSubmit(onSubmit)} isLast>
        <Box display="flex" flexDirection={"column"} gap={1}>
          <span style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}>
            Internacional *
          </span>
          <YesOrNoSwitch
            setValue={(_, value) => {
              if (value !== null) setValue("isInternational", value);
            }}
            value={isInternationWatch}
            type=""
            align="flex-start"
          />
        </Box>

        <Box
          display={"grid"}
          gridTemplateColumns={"1fr 1fr"}
          gap={1}
          alignItems={"center"}
        >
          <Box display="flex" flexDirection={"column"} gap={1}>
            <span style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}>
              Concorrência *
            </span>
            <YesOrNoSwitch
              value={hasCompetitorWatch}
              setValue={(_, value) => {
                if (value !== null) setValue("hasCompetitor", value);
              }}
              type=""
              align="flex-start"
            />
          </Box>

          <Input
            disabled={!hasCompetitorWatch}
            label="Número de concorrentes"
            {...register("numberOfCompetitors")}
            sx={{ flex: 1 }}
          />
        </Box>
        <Box>
          <span>Campos Gerenciais</span>
          <Divider />
        </Box>
        <Box display={"flex"} gap={4} alignItems={"baseline"}>
          <CurrencyInputForm label="Budget do Cliente" name="customerBudget" />
          <Input
            label="Prazo de pagamento do cliente"
            {...register("paymentTerm")}
          />
        </Box>

        <Box display={"flex"} gap={4} alignItems={"center"}>
          <Box marginTop={2.5}>
            <Box display="flex" flexDirection={"column"} gap={1} width={140}>
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Faz adiantamento ? *
              </span>
              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null) setValue("acceptAdvances", value);
                }}
                value={acceptAdvancesWatch}
                type=""
                align="flex-start"
              />
            </Box>
          </Box>

          <Input
            disabled={!acceptAdvancesWatch}
            label="Observações"
            {...register("advanceObservations")}
          />
        </Box>

        <Box display={"flex"} gap={4} alignItems={"center"}>
          <Box marginTop={2.5}>
            <Box display="flex" flexDirection={"column"} gap={1} width={140}>
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Possui risco sacado ? *
              </span>
              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null) setValue("hasDrawnRisk", value);
                }}
                value={hasDrawnRiskWatch}
                type=""
                align="flex-start"
              />
            </Box>
          </Box>

          <Input
            disabled={!hasDrawnRiskWatch}
            label="Qual é a taxa mensal?"
            {...register("monthlyTax")}
            sx={{ flex: 1 }}
          />
          <Input
            disabled={!hasDrawnRiskWatch}
            label="Observações"
            {...register("drawnRiskObservations")}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box display={"flex"} alignItems={"center"} gap={4}>
          <Box marginTop={2.5}>
            <Box display="flex" flexDirection={"column"} gap={1} width={220}>
              <span
                style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}
              >
                Paga fornecedores diretamente ? *
              </span>
              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null) setValue("makesDirectPayments", value);
                }}
                value={makesDirectPaymentsWatch}
                type=""
                align="flex-start"
              />
            </Box>
          </Box>

          <Input
            disabled={!makesDirectPaymentsWatch}
            label="Observações"
            {...register("directPaymentsObservations")}
            sx={{ flex: 1 }}
          />
        </Box>
        <Box>
          <Input label="Honorários" {...register("fee")} sx={{ flex: 1 }} />
          <Box py={1}>
            <RadioGroup.Root
              label="Forma de faturamento"
              value={watch("billingMethod")}
              onChange={(e) => setValue("billingMethod", e.target.value)}
            >
              {billingOptions.map(
                (
                  { value, label }: any,
                  index: React.Key | null | undefined
                ) => (
                  <RadioGroup.Item key={index} label={label} value={value} />
                )
              )}
            </RadioGroup.Root>
          </Box>
          {/* <Box py={1}>
            <RadioGroup.Root
              label="Forma de pagamento"
              value={watch("paymentMethod")}
              onChange={(e) => setValue("paymentMethod", e.target.value)}
            >
              {paymentOptions.map(
                (
                  { value, label }: any,
                  index: React.Key | null | undefined
                ) => (
                  <RadioGroup.Item key={index} label={label} value={value} />
                )
              )}
            </RadioGroup.Root>
          </Box> */}
        </Box>
        <Box>
          <span>Campos Briefing</span>
          <Divider />
        </Box>
        <DatePickerForm
          onBlur={() => {}}
          label="Início Briefing *"
          name="briefingStartDate"
        />

        <DatePickerForm
          onBlur={() => {}}
          label="Envio de briefing (email, ppt ou pdf) *"
          name="sendDate"
        />

        <DatePickerForm
          onBlur={() => {}}
          label="Apresentação *"
          name="presentationDate"
        />
        <Box display={"flex"} gap={2}>
          <Input label="Código PFZ" {...register("pfzCode")} />
          <Input label="Código MAPP" {...register("mappCode")} />
        </Box>
        <Input
          label="Observações (Briefing)"
          {...register("observationsBriefing")}
        />

        <Box>
          <span>Dados do evento</span>
          <Divider />
        </Box>
        <Box display={"flex"} gap={4}>
          <Input
            label="Nome do evento *"
            {...register("name")}
            onChange={(e) => {
              if (e.target.value.length > 99) {
                e.target.value = e.target.value.slice(0, 99);
              }
            }}
          />

          <Box display={"flex"} width={"50%"} alignItems={"flex-end"}>
            <Select
              label="Tipo de evento *"
              value={typeWatch}
              {...register("type")}
              customLabel
            >
              {eventTypes.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <Tooltip
              placement="top-end"
              title={
                <span style={{ fontSize: "14px" }}>
                  {projectTypeDescription[typeWatch] || null}
                </span>
              }
            >
              <IconButton>
                <FaInfoCircle />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box
          display={"grid"}
          gap={4}
          gridTemplateColumns={"1fr 1fr"}
          alignItems={"baseline"}
        >
          <Input label="Local do evento" {...register("local")} />

          <Box display="flex" flexDirection={"column"} gap={1} width={140}>
            <span style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}>
              Endereço fixo *
            </span>
            <YesOrNoSwitch
              setValue={(_, value) => {
                if (value !== null) setValue("addressFixed", value);
              }}
              value={addressFixedWatch}
              type=""
              align="flex-start"
            />
          </Box>
        </Box>
        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input
            label="CEP"
            {...register("zip_code")}
            onChange={(e) => {
              if (
                e.target.value.includes("-") ||
                e.target.value.includes(".")
              ) {
                e.target.value = e.target.value.replace(/[-.]/g, "");
              }
              if (e.target.value.length > 8) {
                e.target.value = e.target.value.slice(0, 8);
              }
            }}
            onKeyDown={(e) => {
              // if (!/[0-9]/.test(e.key)) {
              //   e.preventDefault();
              // }
            }}
            onBlur={(e) => {
              fetchCepData(e.target.value);
              setValue("zip_code", e.target.value);
            }}
          />
          <Input label="País" {...register("country")} />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input label="Estado" {...register("state")} />

          <Input label="Município" {...register("city")} />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input label="Bairro" {...register("neighborhood")} />

          <Input
            label="Número"
            {...register("number")}
            onChange={(e) => {
              const numberRegex = new RegExp("^[0-9]*$");
              if (!numberRegex.test(e.target.value)) {
                e.target.value = e.target.value.replace(/\D/g, "");
              }
            }}
          />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input label="Logradouro" {...register("complement")} />
          <Input label="Complemento" {...register("complement2")} />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <RadioGroup.Root
            label="Formato de evento *"
            value={watch("eventFormat")}
            onChange={(e) => setValue("eventFormat", e.target.value)}
          >
            {eventFormats.map(({ value, label }, index) => (
              <RadioGroup.Item key={index} label={label} value={value} />
            ))}
          </RadioGroup.Root>

          <Input label="Metragem" {...register("metrics")} />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input label="Perfil do evento" {...register("eventProfile")} />
          <Input label="Público" {...register("audience")} />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Input
            label="Nº de pessoas *"
            {...register("numberOfPeople")}
            type="number"
          />
        </Box>

        <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
          <Box display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>
            <DatePickerForm
              label="Data  início *"
              name="startDate"
              onBlur={handleBlur}
              control={control}
            />

            <DatePickerForm
              label="Data fim *"
              name="endDate"
              onBlur={handleBlur}
              control={control}
            />
          </Box>

          <Box display="flex" flexDirection={"column"} gap={1} width={140}>
            <span style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}>
              Dias corridos *
            </span>
            <YesOrNoSwitch
              setValue={(_, value) => {
                if (value !== null) setValue("sequenceDays", value);
              }}
              value={sequenceDaysWatch}
              type=""
              align="flex-start"
            />
          </Box>
        </Box>

        <Box
          display={"grid"}
          gap={4}
          gridTemplateColumns={"1fr 1fr"}
          alignItems={"baseline"}
        >
          <Input
            label="Duração do evento (em dias)"
            {...register("duration")}
            type="number"
            disabled
          />

          <Box display="flex" flexDirection={"column"} gap={1} width={250}>
            <span style={{ fontSize: 13, color: "#00000099", fontWeight: 400 }}>
              Mesmo horário início e fim todos os dias *
            </span>
            <YesOrNoSwitch
              setValue={(_, value) => {
                if (value !== null) setValue("sameTimeEveryDay", value);
              }}
              value={sameTimeEveryDayWatch}
              type=""
              align="flex-start"
            />
          </Box>
        </Box>

        <Box
          display={"grid"}
          gap={4}
          gridTemplateColumns={"1fr 1fr"}
          width={"50%"}
        >
          <Input label="Hora início" {...register("hourInit")} type="time" />
          <Input label="Hora fim" {...register("hourEnd")} type="time" />
        </Box>
        <Input
          label="Descreva horários específicos"
          {...register("schedulleObservations")}
        />
        <Input label="Objetivo do evento" {...register("objective")} />
        <Input label="Desafio do cliente" {...register("customerChallenge")} />
        <Input label="Observações" {...register("observationsEvent")} />
      </TabContent>
    </FormProvider>
  );
};

export default ProjectEvent;
