import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
} from "@mui/material";
import { BiChevronDown } from "react-icons/bi";
import {
  BudgetItemType,
  BudgetItemTypeEnum,
  HeaderOptions,
  TotalByType,
  Totals,
  TotalSplited,
} from "../../../../types/budget/budget";
import {
  createOptionsFromEnum,
  formatCurrency,
} from "../../../../utils/formatters";
import { ServiceData } from "../../../../types/budget/service-item";

type Props = {
  type: HeaderOptions;
  totalPerCalcOption: TotalByType;
  services: ServiceData[];
};

export default function TotalAccordion({
  services,
  totalPerCalcOption,
  type,
}: Props) {
  const itemTypeOptions = createOptionsFromEnum(BudgetItemTypeEnum);

  function renderBudgetItemSummary() {
    return itemTypeOptions.map(
      (el: { value: BudgetItemType; text: string }) => {
        const servicesPerType = services.filter(
          (service) => service.type === el.value
        );
        const total = reduceTotalsArr(
          totalPerCalcOption[el.value] || [],
          "totals"
        );

        const taxes = reduceTotalsArr(totalPerCalcOption[el.value], "taxes");
        if (servicesPerType.length < 1) return <></>;
        return (
          <BudgetItemSummary
            type={el.value}
            servicesLength={servicesPerType.length}
            total={total}
            taxes={taxes}
          />
        );
      }
    );
  }

  function reduceTotalsArr(
    arr: TotalSplited[],
    key: "totals" | "taxes"
  ): Totals {
    return (
      arr?.reduce(
        (acc: Totals, item: TotalSplited) => {
          acc.totalInBRL += item[key].totalInBRL;
          acc.totalInCurrency += item[key].totalInCurrency;
          return acc;
        },
        { totalInBRL: 0, totalInCurrency: 0 } // Initial accumulator value
      ) || { totalInBRL: 0, totalInCurrency: 0 }
    );
  }

  return (
    <Accordion sx={{ mb: 1 }}>
      <AccordionSummary
        sx={{
          background: "#F8F8F8",
          borderRadius: "4px",
        }}
        expandIcon={<BiChevronDown />}
      >
        <span style={{ fontWeight: "bold" }}>{type}</span>
        <Box ml={"auto"} mr={1}>
          <span>
            {formatCurrency(
              reduceTotalsArr(totalPerCalcOption.SUM, "totals").totalInBRL
            )}
          </span>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {renderBudgetItemSummary()}
        <Divider sx={{ m: 1 }} />
        <Box display={"flex"} justifyContent={"space-between"} my={1}>
          <Box ml={3}> Soma </Box>
          <Box display="flex" width={"50%"} justifyContent={"space-evenly"}>
            {/* <SumDiv
              totalInBRL={
                reduceTotalsArr(totalPerCalcOption.SUM, "taxes").totalInBRL
              }
              // totalInCurrency={totalPerCalcOption.SUM.taxes?.totalInCurrency}
              title={"Taxas"}
            /> */}
            <SumDiv
              totalInBRL={
                reduceTotalsArr(totalPerCalcOption.SUM, "totals").totalInBRL
              }
              // totalInCurrency={totalPerCalcOption.SUM.totals.totalInCurrency}
              title={"Totais"}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

type SumDivProps = {
  totalInBRL: number;
  title: string;
};
function SumDiv({ totalInBRL, title }: SumDivProps) {
  return (
    <Box>
      <Box color={"#aaa"}>{title}</Box>
      <Box color={"#555"}>{formatCurrency(totalInBRL)}</Box>
    </Box>
  );
}

type BudgetItemSummaryProps = {
  type: BudgetItemType;
  servicesLength: number;
  total: Totals;
  taxes: Totals;
};
function BudgetItemSummary({
  type,
  servicesLength,
  total,
  taxes,
}: BudgetItemSummaryProps) {
  return (
    <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
      <Box ml={3}>
        <Box>{BudgetItemTypeEnum[type]}</Box>
        <Box color={"#aaa"}>{servicesLength} cadastros</Box>
      </Box>
      <Box display="flex" width={"50%"} justifyContent={"space-evenly"}>
        {/* <SumDiv totalInBRL={taxes.totalInBRL} title="Taxas" /> */}
        <SumDiv totalInBRL={total.totalInBRL} title="Totais" />
      </Box>
    </Box>
  );
}
