import { Box } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../../../../utils/formatters";

type ItemValueProps = {
  total: number;
  savings: number;
  title: string
};
const ItemValue = ({ total, savings, title }: ItemValueProps) => {
  const savingsColor = !!savings && savings > 0 ? "#EA5455" : "#28C76F";
  const savingsSign = !!savings && savings > 0 ? "+" : "-";
  return (
    <Box
      sx={{ fontWeight: "bold" }}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Box>{title}:</Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
        <Box>{formatCurrency(total)}</Box>
        {savings !== 0 && (
          <Box sx={{ color: savingsColor }}>
            {" "}
            {savingsSign} {formatCurrency(Math.abs(savings))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ItemValue;
