import { margin } from "@mui/system";
import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: "15px",
    fontSize: "12px",
  },
  exportHeader: {
    display: "flex",
    margin: "15px auto",
    width: "98%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "0 auto",
  },
  header2: {
    fontSize: 20,
  },
  viewer: {
    width: window.innerWidth - 36, // the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  clientDataDiv: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: 16,
    padding: 5,
    margin: 15,
  },
  double: {
    width: "26%",

    backgroundColor: "#a9d6fd",
    alignItems: "center",
  },
  budgetDataDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: 5,
  },
  mainDataDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  mainDataDivBorders: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  textDiv1: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#82d29a",
  },
  textDiv2: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#76b6ef",
  },
  textDiv3: {
    width: 300,
    padding: "0 10px",
    backgroundColor: "#f1cf71",
  },
  textDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0",
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "auto",
  },
  row: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#DDD",
    padding: 8,
    width: "100%",
    justifyContent: "flex-start",
  },
  rowTitles: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    fontSize: "10px",
  },
  rowNormal: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    backgroundColor: "#eee",
  },
  rowType: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#eee",
    fontSize: "12px",
  },
  header: {
    borderTop: "none",
    margin: "0 auto",
    fontSize: 18,
  },
  bold: {
    fontWeight: "bold",
  },
  row1: {
    width: "50%",
  },
  providerDiv: {
    width: "15%",
    alignItems: "center",
  },
  enumDiv: {
    width: "5%",
    alignItems: "center",
  },
  typeDiv: {
    width: "15%",
    alignItems: "center",
  },
  obsDiv: {
    width: "15%",
    alignItems: "center",
  },
  mainDiv: {
    width: "80%",
    alignItems: "center",
  },
  halfMainDiv: {
    width: "40%",
    alignItems: "center",
  },
  date: {
    width: "15%",
  },
  small: {
    width: "8%",
  },
  tariff: {
    width: "15%",
  },
  total: {
    width: "15%",
  },
  orcamentosDiv: {
    backgroundColor: "#a9d6fd",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "30%",
  },
  infoDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "50px",
  },
  locationDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },
  tagsDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    height: "25px",
  },
  budgetTitleDiv: {
    backgroundColor: "#E5E5E5",
    height: "25px",
    display: "flex",
    paddingLeft: "10px",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  deadlineDiv: {
    height: "25px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "10px",
    width: "100%",
  },
  servicesDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    margin: "auto",
  },
  serviceTypeDiv: {
    backgroundColor: "#EEEEEE",
    width: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  singleServiceDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "70%",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    margin: "auto",
  },
  // novo export
  headerDiv: {
    width: "49%",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  backgroundBlack: {
    backgroundColor: "#000",
  },
  backgroundGray: {
    backgroundColor: "#ddd",
  },
  whiteText: {
    color: "#FFF",
  },
  grayText: {
    color: "#555",
  },
  childHeaderDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  childHeaderDiv2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  textSpan: {
    fontSize: 12,
  },
  textBudget: {
    fontSize: 14,
  },
  textHeader: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    margin: "auto",
    color: "#7C7C7C",
  },

  // fim novo export

  verticaltext: {
    transform: "rotate(-90deg)",
    position: "absolute",

    // -ms-transform: 'rotate(-90deg)',
    // -ms-transform-origin:r'ight, top',
    // -webkit-transform: 'rotate(-90deg)',
    // -webkit-transform-origin:r'ight, top',
  },
  childBudgetDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    margin: "8px 0",
  },

  row2: {
    width: "16%",
    margin: "0 auto",
    alignItems: "center",
  },
  row3: {
    width: "6.25%",
    alignItems: "center",
  },
  row4: {
    width: "7%",
    alignItems: "center",
  },
  row5: {
    width: "27%",
  },
});
