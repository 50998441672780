import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { InputDiv, SummaryDiv, RadioDiv, InputRow, StyledBox } from "../styles";
// import SaveImg from "../../../assets/styled-icons/Save.png";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";
import { TransfersFieldType, RsvpType } from "../../../../types/rsvp/index";
import { Input } from "../../../Input";
import CurrencyInputForm from "../../../CurrencyInputForm";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}


export function Transfer({ onSubmitNav, isDisabled }: Props) {
  const { rsvpId, updateTransfer, createRsvp, transfer } = useRsvp()
  const obj: TransfersFieldType = {
    companion: "",
    scheduleChange: "",
    companionObs: "",
    companionValueObs: 0,
    scheculeChangeObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset } = methods

  function onSubmit(form: TransfersFieldType) {
    rsvpId ? updateTransfer(form) : createRsvp({ transfer: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (transfer) {
      reset(transfer);
    }
  }, [transfer]);

  return (<FormProvider {...methods}>

    <StyledBox onSubmit={handleSubmit(onSubmit)} >
      <h3>6.Transfer</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Acompanhantes</strong>
          <span>Padrão de compliance limita ou não permite acompanhantes</span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("companion")}
              onChange={(e) => setValue("companion", e.target.value)}
            >
              <FormControlLabel
                value="NO_SERVICE_USAGE_COMPLIANCE"
                control={<Radio />}
                label="Não podem usufruir do serviço (conforme padrão de compliance)"
              />
              <FormControlLabel
                value="FREE_SERVICE_USAGE"
                control={<Radio />}
                label="Podem usufruir do serviço sem custo algum"
              />
              <FormControlLabel
                value="SERVICE_WITH_SPECIFIED_COST"
                control={<Radio />}
                label="Podem usufruir do serviço e o valor é:"
              />

            </RadioGroup>
          </FormControl>
          <CurrencyInputForm label={"Valor"} name='companionValueObs' />

          <Input
            label="Outros"
            size="small"
            {...register('companionObs')}

          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Alteração somente de horários</strong>
          <span>Alteração de transfer em relação a alteração no trecho</span>
        </SummaryDiv>
        <RadioDiv>
          <FormControl>
            <RadioGroup
              value={watch("scheduleChange")}
              onChange={(e) => setValue("scheduleChange", e.target.value)}
            >
              <FormControlLabel
                value="CAN_BE_MAINTAINED_WITHIN_ALLOWED_HOURS"
                control={<Radio />}
                label="Pode ser mantido, se os horários estiverem dentro do permitido (conforme padrão de compliance)"
              />
              <FormControlLabel
                value="CANNOT_BE_MAINTAINED"
                control={<Radio />}
                label="Não pode ser mantido"
              />
            </RadioGroup>
          </FormControl>
          <Input
            label="Outros"
            size="small"
            {...register('scheculeChangeObs')}
          />
        </RadioDiv>
      </InputDiv>
      <br />
      <Button
        disabled={isDisabled}
        variant="contained"
        
        style={{ position: 'relative', left: '90%' }}
        type="submit"
      >
        Salvar
      </Button>
    </StyledBox>
  </FormProvider>
  );
}
