import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";

import MinimalDragAndDrop from "../../../UI/MinimalDragAndDrop";

const FileUpload: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const handleFileChange = (file: File) => {
    if (file) {
      setUploadedFiles([...uploadedFiles, file]); // Convert FileList to File[]
    }
  };
  const handleFileRemoval = (fileIndex: number) => {
    console.log(fileIndex, "fileindex");
    setUploadedFiles(uploadedFiles.filter((el, index) => index !== fileIndex));
  };

  console.log(uploadedFiles);
  return (
    <Accordion
      sx={{ my: 1.5, borderRadius: "4px" }}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<BiChevronDown />}
        id="panel1a-header"
        sx={{
          minHeight: 40,
          maxHeight: 40,
          borderRadius: "4px",
          "&.Mui-expanded": {
            minHeight: 40,
            maxHeight: 40,
          },
        }}
      >
        <span style={{ fontWeight: "bold" }}>Anexos</span>
      </AccordionSummary>

      <AccordionDetails>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <span style={{ color: "#929292", margin: "5px 0" }}>
            Arquivos de apoio (recibo, comprovante, descritivo, nf)
          </span>
          <MinimalDragAndDrop
            projectId={""}
            area={""}
            index={0}
            onFileUpload={handleFileChange}
            title={""}
            onRemoveFile={handleFileRemoval}
          />
          <Box>
            <span style={{ color: "#929292", margin: "5px 0" }}>
              Arquivos enviados
            </span>
            {uploadedFiles.map((file, index) => (
              <li key={index} style={{ listStyle: "none", margin: "10px 0" }}>
                <MinimalDragAndDrop
                  fileData={file}
                  title={""}
                  projectId={""}
                  area={""}
                  index={index}
                  onRemoveFile={handleFileRemoval}
                />
              </li>
            ))}
          </Box>
        </Box>
        <Box display={"flex"} m={1}>
          <Button onClick={() => {}} sx={{ ml: "auto" }} variant="outlined">
            Salvar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FileUpload;
