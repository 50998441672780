import {
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  createFilterOptions,
  TextField,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { LabeledAutoComplete } from "../../UI/LabeledAutoComplete";
import { GridSortDirection } from "@mui/x-data-grid";
import SupplierService from "../../../services/supplier.service";
import { useBudget } from "../../../contexts/BudgetContext/projectbudgets.context";
import { toast } from "react-toastify";

const INITIAL_STATE = {
  page: 0,
  pageSize: 20,
  order: "asc" as GridSortDirection,
  sortField: "name",
  search: "",
  origin: "",
};

type PropTypes = {
  onSelectSupplier(value: string, field: string): void;
  provider_id: string;
  title: string;
  disabled?: boolean;
  isBudget?: boolean;
};

const SupplierAutoComplete: React.FC<PropTypes> = ({
  onSelectSupplier,
  provider_id,
  title,
  disabled,
  isBudget,
}: PropTypes) => {
  const [inputSupplierValue, setInputSupplerValue] = useState("");
  const { activeBudget, activeCalcOption } = useBudget();

  const { data: provider } = useQuery({
    queryKey: ["provider", provider_id],
    queryFn: () => SupplierService.getProviders({ id: provider_id }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false, // Disable refetch on window focus
    refetchOnMount: false, // Disable refetch on component mount
    cacheTime: 1000 * 60 * 10, // Data stays in cache for 10 minutes
  });
  const [supplierAutoCompleteSearch, setSupplierAutoCompleteSearch] =
    useState<any>(INITIAL_STATE);
  const { isLoading, data } = useQuery({
    queryKey: [
      "providers_search",
      supplierAutoCompleteSearch,
      inputSupplierValue,
    ],
    queryFn: () =>
      SupplierService.search({
        ...supplierAutoCompleteSearch,
        search: inputSupplierValue,
      }),
  });

  const [supplierValue, setSupplierValue] = useState(null);
  function supplierChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: any | null
  ) {
    if (!!isBudget) {
      const hasTheSameForCalcOption = activeBudget.supplierList.find(
        (el) => el.supplierId === newValue?._id && el.calcOption === activeCalcOption
      );
      if(hasTheSameForCalcOption) {
        setSupplierValue(null);
        setInputSupplerValue("")
        return toast.warn('Fornecedor duplicado para o tipo de cálculo ativo, use o existente')
      }
    }
    setSupplierValue(newValue);
    onSelectSupplier(newValue?._id || "", "provider_id");
  }
  function supplierInputChangeHandler(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (newValue === "" && reason === "reset") return;
    setInputSupplerValue(newValue);
  }

  useEffect(() => {
    if (provider_id === "") {
      setInputSupplerValue("");
      setSupplierValue(null);
    }
  }, [provider_id]);

  useEffect(() => {
    if (!!provider && provider[0]?._id) {
      setSupplierValue(provider[0]);
      setInputSupplerValue(provider[0].name);
    }
  }, [provider]);

  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.name + option.tax_id,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LabeledAutoComplete
        filterOptions={filterOptions}
        options={data?.providers || []}
        getOptionLabel={(option) => option.name || ""}
        size="small"
        inputValue={inputSupplierValue}
        value={supplierValue}
        onInputChange={supplierInputChangeHandler}
        onChange={supplierChangeHandler}
        label={`Fornecedor`}
        disabled={disabled || false}
        renderInput={(params) => (
          <TextField
            placeholder="Selecione um fornecedor"
            {...params}
            fullWidth
            style={{ background: "#FFF" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SupplierAutoComplete;
