import { toast } from "react-toastify";
import {
  Budget,
  ItemTypeListPerCalcOption,
  ItemTypeList,
} from "../../types/budget/budget";
import { initialServiceData } from "../../types/budget/service-item";
import { uuid } from "../../utils/utils";
import { initialItemTypeList } from "./consts";

const isKey = (key: string, obj: object): key is keyof typeof obj => key in obj;
export interface ActiveBudgetAction {
  type:
    | "CHANGE_CALC_TYPE"
    | "NO_ACTIVE_BUDGET"
    | "NEW_ACTIVE_BUDGET"
    | "ADD_SUPPLIER"
    | "UPDATE_SUPPLIER_LIST"
    | "ADD_BUDGET_ITEM"
    | "DELETE_SERVICE"
    | "ADD_SERVICE"
    | "YES_NO_SWITCH_CHANGE"
    | "UPDATE_SERVICE"
    | "UPDATE_FROM_API"
    | "UPDATE_CALC_VALUES"
    | "DELETE_BUDGET_ITEM"
    | "DELETE_SUPPLIER"
    | "CREATE_PAYMENT_INFO"
    | "UPDATE_PAYMENT_INFO"
    | "UPDATE_ANEX_FILES"
    | "CREATE_ANEX_FILES";
  payload: any;
}


export function activeBudgetReducer(state: Budget, action: ActiveBudgetAction) {
  let updatedBudget = { ...state };
  switch (action.type) {
    case "CHANGE_CALC_TYPE":
      updatedBudget.itemTypeList = {
        ...updatedBudget.itemTypeList,
        [action.payload.key]: action.payload.value,
      };
      toast.success("Tipo de calculo mudado para:" + action.payload.value);
      break;
    case "NO_ACTIVE_BUDGET":
      updatedBudget = {} as Budget;
      break;
    case "NEW_ACTIVE_BUDGET":
      updatedBudget = action.payload;
      break;
    case "ADD_SUPPLIER":
      updatedBudget.supplierList.push({
        supplierId: "",
        calcOption: action.payload.calcOption,
      });
      break;
    case "UPDATE_SUPPLIER_LIST":
      // ao atualizar um fornecedor também devem ser atualizados
      // os serviços que até então tinham supplierId: currentId?
      const index = updatedBudget.supplierList.findIndex(
        (el) =>
          el.supplierId === action.payload.currentId &&
          el.calcOption === action.payload.calcOption
      );
      updatedBudget.supplierList[index] = {
        supplierId: action.payload.newId,
        calcOption: action.payload.calcOption,
      };
      // updatedBudget.budgetServices = updatedBudget.budgetServices.map(el => {
      //   if(el.)
      // })
      toast.success("Lista de fornecedores atualizada.");
      break;
    case "DELETE_SUPPLIER":
      // Ao deletar um fornecedor, todos serviços dele também serão apagados!
      updatedBudget.addedItemTypeList = updatedBudget.addedItemTypeList.filter(
        (el) =>
          !(
            el.supplierId === action.payload.supplierId &&
            el.calcOption === action.payload.calcOption
          )
      );
      updatedBudget.budgetServices = updatedBudget.budgetServices.filter(
        (el) =>
          !(
            el.supplierId === action.payload.supplierId &&
            el.calcOption === action.payload.calcOption
          )
      );
      
      updatedBudget.supplierList = updatedBudget.supplierList.filter(
        (el) =>
          !(
            el.supplierId === action.payload.supplierId &&
            el.calcOption === action.payload.calcOption
          )
      );
      break;
    case "ADD_BUDGET_ITEM":
      if (state.addedItemTypeList?.includes(action.payload)) {
        toast.warn("Tipo já existente");
        return state;
      }
      updatedBudget.addedItemTypeList.push(action.payload);
      break;
    case "DELETE_SERVICE":
      const indexDeleted = state.budgetServices.findIndex(
        (el) => el._id === action.payload
      );
      updatedBudget.budgetServices = updatedBudget.budgetServices.filter(
        (el, index) => index !== indexDeleted
      );
      toast.success("Serviço removido");
      break;
    case "ADD_SERVICE":
      updatedBudget.budgetServices.push({
        ...initialServiceData,
        type: action.payload.type,
        supplierId: action.payload.supplierId,
        supplierName: action.payload.supplierName,
        calcOption: action.payload.calcOption,
        _id: uuid(),
      });
      toast.success("Serviço adicionado");
      break;
    case "YES_NO_SWITCH_CHANGE":
      if (
        !!updatedBudget.itemTypeList &&
        !!updatedBudget.itemTypeList[
          action.payload.activeCalcOption as keyof ItemTypeListPerCalcOption
        ]
      ) {
        updatedBudget.itemTypeList[
          action.payload.activeCalcOption as keyof ItemTypeListPerCalcOption
        ][action.payload.type] = action.payload.newValue;
      } else if (
        !!updatedBudget.itemTypeList &&
        !updatedBudget.itemTypeList[
          action.payload.activeCalcOption as keyof ItemTypeListPerCalcOption
        ]
      ) {
        updatedBudget.itemTypeList = {
          ...updatedBudget.itemTypeList,
          [action.payload.activeCalcOption as keyof ItemTypeListPerCalcOption]:
            {
              [action.payload.type]: action.payload.newValue
            },
        };
     
      } else {
        updatedBudget = { ...updatedBudget, itemTypeList: initialItemTypeList };
        updatedBudget.itemTypeList[
          action.payload.activeCalcOption as keyof ItemTypeListPerCalcOption
        ][action.payload.type] = action.payload.newValue;
      }
      toast.success("Lista de opções atualizada");
      break;
    case "UPDATE_SERVICE":
      const servicesList = updatedBudget.budgetServices.map((el) => {
        if (el._id === action.payload._id) {
          return action.payload;
        } else return el;
      });
      updatedBudget.budgetServices = servicesList;
      toast.success("Serviço adicionado!");
      break;
    case "UPDATE_CALC_VALUES":
      const servicesListCalc = updatedBudget.budgetServices.map((el) => {
        if (el._id === action.payload._id) {
          return action.payload;
        } else return el;
      });
      updatedBudget.budgetServices = servicesListCalc;
      break;
    
    
    case "UPDATE_PAYMENT_INFO":
      const newPaymentInfoArr = updatedBudget.paymentSolicitation.map((el) => {
        if (
          el.supplierId === action.payload.supplierId &&
          el.type === action.payload.type &&
          el.calcOption === action.payload.calcOption
        ) {
          return action.payload.paymentInfo;
        }
        return el;
      });
      updatedBudget.paymentSolicitation = newPaymentInfoArr;
      toast.success("Solicitação de pagamento atualizada!");

      break;
    case "CREATE_PAYMENT_INFO":
      updatedBudget.paymentSolicitation.push(action.payload.paymentInfo);
      toast.success("Solicitação de pagamento criada!");

      break;

    case "UPDATE_ANEX_FILES":
      const newFilesArr = updatedBudget.paymentSolicitation.map((el) => {
        if (
          el.supplierId === action.payload.supplierId &&
          el.type === action.payload.type &&
          el.calcOption === action.payload.calcOption
        ) {
          return action.payload.paymentInfo;
        }
        return el;
      });
      updatedBudget.paymentSolicitation = newFilesArr;
      toast.success("Solicitação de pagamento atualizada!");

      break;
    case "CREATE_ANEX_FILES":
      updatedBudget.paymentSolicitation.push(action.payload.paymentInfo);
      toast.success("Solicitação de pagamento criada!");

      break;
    case "UPDATE_FROM_API":
      updatedBudget = action.payload;
      break;
    case "DELETE_BUDGET_ITEM":
      // Ao deletar um budgetItem, todos serviços dele também serão apagados!
      updatedBudget.addedItemTypeList = updatedBudget.addedItemTypeList.filter(
        (el) =>
          !(
            el.supplierId === action.payload.supplierId &&
            el.type === action.payload.type &&
            el.calcOption === action.payload.calcOption
          )
      );
      updatedBudget.budgetServices = updatedBudget.budgetServices.filter(
        (el) =>
          !(
            el.supplierId === action.payload.supplierId &&
            el.type === action.payload.type &&
            el.calcOption === action.payload.calcOption
          )
      );
      
      break;
    default:
      break;
  }
  if (action.type !== "UPDATE_FROM_API") {
    updatedBudget.isApiUpdate = false;
  }

  return { ...updatedBudget };
}
