import { Box, Button, Card, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Customers } from "../../components/templates/Partners/Customers";
import { Suppliers } from "../../components/templates/Partners/Suppliers";
import { FaPlus } from "react-icons/fa";
import { Customer } from "../../components/Customer";
import { useHistory, useParams } from "react-router-dom";
import { Supplier } from "../../components/Supplier";
import { AuthContext } from "../../contexts/auth";

export function Partners() {
  const { actionOptions } = useContext(AuthContext);
  const [optionActive, setOptionActive] = useState(0);
  const history = useHistory();
  const [clientEdit, setclientEdit] = useState<any>();
  const [supplierEdit, setSupplierEdit] = useState<any>();

  const handleEdit = (type: string, item: any): void => {
    if (type === "client") {
      setclientEdit(item);
      history.push(`/partners/customer?customerId=${item?._id}`);
    }
    if (type === "supplier") {
      setSupplierEdit(item);
      history.push(`/partners/supplier?supplierId=${item?._id}`);
    }
  };
  const options = [
    { title: "Cliente", template: <Customer edit={clientEdit} /> },
    { title: "Clientes", template: <Customers onSelect={handleEdit} /> },
    { title: "Fornecedores", template: <Suppliers onSelect={handleEdit} /> },
    { title: "Fornecedor", template: <Supplier edit={supplierEdit} /> },
  ];
  useEffect(() => {
    switch (true) {
      case window.location.pathname.includes("customers"):
        setOptionActive(1);
        setclientEdit(undefined);
        setSupplierEdit(undefined);
        break;
      case window.location.pathname.includes("suppliers"):
        setOptionActive(2);
        setclientEdit(undefined);
        setSupplierEdit(undefined);
        break;
      case window.location.pathname.includes("customer"):
        setOptionActive(0);
        break;
      case window.location.pathname.includes("supplier"):
        setOptionActive(3);
        break;
    }
  }, [window.location.pathname]);

  const getTitle = () => {
    let title = "Parceiros/";
    title = title + options[optionActive].title;
    if (optionActive === 0) {
      if (clientEdit?.handle_id) {
        title = title + `/${clientEdit?.handle_id}`;
      }
    }
    if (optionActive === 3) {
      if (supplierEdit?.handle_id) {
        title = title + `/${supplierEdit?.handle_id}`;
      }
    }
    return title;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
      width={"100%"}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant={"h5"}>
          {/* Parceiros/{`${options[optionActive].title}`}
          {clientEdit?.handle_id ? `/${clientEdit?.handle_id}` : ""} */}
          {getTitle()}
        </Typography>
        {/* {!clientEdit && (
          <Button
            disabled={!actionOptions.createParceiros}
            style={{
              marginRight: "15px",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
            }}
            variant="outlined"
            onClick={() => {
              setclientEdit(undefined);
              setSupplierEdit(undefined);
              optionActive === 1
                ? history.push("/partners/customer")
                : optionActive === 2
                ? history.push("/partners/supplier")
                : null;
            }}
          >
            <FaPlus /> &nbsp;
            {optionActive === 1
              ? "Cliente"
              : optionActive === 2
              ? "Fornecedor"
              : null}
          </Button>
        )} */}
      </Box>

      <Card
        sx={{
          display: "flex",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          overflow: "auto",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1.5}
          px={"24px"}
          pt={"24px"}
          pb={"44px"}
          borderRight={"1px solid #DBDADE"}
          width={"100%"}
          height={"73vh"}
        >
          {options[optionActive].template}
        </Box>
      </Card>
    </Box>
  );
}
