import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import ActionSelect from "../ActionSelect";
import {
  Budget,
  BudgetItemType,
  BudgetStatus,
  BudgetStatusEnum,
  BudgetSummaryActionOptions,
  itemOptions,
  StatusBudget,
} from "../../../../../types/budget/budget";
import { useState } from "react";
import { useBudget } from "../../../../../contexts/BudgetContext/projectbudgets.context";
import { createOptionsFromEnum } from "../../../../../utils/formatters";
import { BiChevronDown } from "react-icons/bi";
import { ItemTotalsSummary } from "../../BudgetItem/ItemTotalsSummary";
import BudgetItem from "../BudgetDetail/BudgetItem";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { useProject } from "../../../../../contexts/project.context";
import Swal from "sweetalert2";

type Props = {
  budget: Budget;
  index: number;
  status?: StatusBudget;
  version?: number;
};
const BudgetSummary: React.FC<Props> = ({
  budget,
  index,
  status,
  version,
}: Props) => {
  const { tabActive } = useProject();
  const title =
    tabActive === 8
      ? `Orçamento ${index + 1} - ${BudgetStatusEnum[status || "OPEN"]}`
      : tabActive === 9 && version
      ? `Orçamento ${index + 1} - Negociação ${version}`
      : `Orçamento ${index + 1} - Fechamento`;

  const [isExpanded, setIsExpanded] = useState(false);
  const { createBudget, updateBudget } = useBudget();
  
  const actionOptions = createOptionsFromEnum(BudgetSummaryActionOptions);

  type ActionMethodsMap = {
    [key in keyof typeof BudgetSummaryActionOptions]: any;
  };
  const actionMethods: ActionMethodsMap = {
    APROVE: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status do Orçamento ${
          index + 1
        } para Aprovado.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, { ...budget, status: "APROVED" });
          }
          Swal.fire({
            title: "Atualizar",
            text: "O status do orçamento foi atualizado",
            icon: "success",
          });
        }
      });
    },
    DENY: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status do Orçamento ${
          index + 1
        } para NEGADO.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, { ...budget, status: "DENIED" });
          }
          Swal.fire({
            title: "Atualizar",
            text: "O status do orçamento foi atualizado",
            icon: "success",
          });
        }
      });
    },
    NEGOTIATE: () => {
      Swal.fire({
        title: "Alterar status do orçamento?",
        text: `Você está alterando o status do Orçamento ${
          index + 1
        } para Negociação.`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, alterar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              status: "NEGOTIATE",
              negotiationVersion: budget.negotiationVersion + 1,
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "O status do orçamento foi atualizado",
            icon: "success",
          });
        }
      });
    },
    DUPLICATE: () => {
      Swal.fire({
        title: "Duplicar orçamento?",
        text: `Você está duplicando o Orçamento ${index + 1}.`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, duplicar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            const newBudget = JSON.parse(JSON.stringify(budget));
            delete newBudget._id;
            newBudget.negotiationVersion = 0;
            newBudget.status = "OPEN";
            await createBudget(newBudget);
          }
          Swal.fire({
            title: "Atualizar",
            text: "Orçamento duplicado",
            icon: "success",
          });
        }
      });
    },
    DELETE: () => {
      Swal.fire({
        title: "Tem certeza que deseja excluir este orçamento?",
        text: `Você está excluindo permanentemente o Orçamento ${
          index + 1
        }. Esta ação não pode ser desfeita`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, excluir permanentemente",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            switch (tabActive) {
              case 9:
                return updateBudget(budget._id, {
                  ...budget,
                  status: budget.negotiationVersion > 1 ? "NEGOTIATE" : "OPEN",
                  negotiationVersion:
                    budget.negotiationVersion > 1
                      ? budget.negotiationVersion - 1
                      : 0,
                });
              case 10:
                return;
              case 8:
              default:
                if (!!budget._id) {
                  return updateBudget(budget._id, {
                    ...budget,
                    status: "DELETED",
                  });
                }
            }
          }
          Swal.fire({
            title: "Atualizar",
            text: "Orçamento duplicado",
            icon: "success",
          });
        }
      });
    },
    NEW_VERSION: () => {
      Swal.fire({
        title: "Criar nova versão?",
        text: `Você está criando uma nova versão de negociação para o Orçamento ${
          index + 1
        }.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, criar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!!budget._id) {
            updateBudget(budget._id, {
              ...budget,
              negotiationVersion: budget.negotiationVersion + 1,
            });
          }
          Swal.fire({
            title: "Atualizar",
            text: "Nova versão criada",
            icon: "success",
          });
        }
      });
    },
  };
  async function triggerAction(
    action: keyof typeof BudgetSummaryActionOptions
  ) {
    return actionMethods[action]();
  }

  return (
    <Accordion
      onChange={(_, expanded) => {
        setIsExpanded(expanded);
      }}
      sx={{
        border: "1px solid #eee",
        borderRadius: "4px",
        mb: 0.5,
        fontSize: "13px",
      }}
    >
      <AccordionSummary
        expandIcon={<BiChevronDown />}
        sx={{
          minHeight: 60,
          maxHeight: 60,
          "&.Mui-expanded": {
            minHeight: 30,
            maxHeight: 30,
            mt: 1,
          },
        }}
      >
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <RadioGroup.Item
            label={title}
            value={
              version && budget._id
                ? budget?._id + `-version-${version}`
                : budget._id || ""
            }
          />
          {!isExpanded && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <ItemTotalsSummary
                total={
                  budget?.budgetValuesTotal?.byItemType?.SUM?.totals?.totalInBRL || 0
                }
                savings={0}
              />
              {/* <Box>Total: </Box> <Box ml={"auto"}>R$ 12.987,45 </Box> */}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {itemOptions.map((el: { value: BudgetItemType; label: string }) => {
          const servicesArr = budget.budgetServices.filter(
            (item) => item.type === el.value
          );
          if (servicesArr.length < 1) return <></>;
          return (
            <BudgetItem
              selectable={false}
              type={el.value}
              servicesArr={budget.budgetServices.filter(
                (item) => item.type === el.value
              )}
              total={
                // budget.budgetValuesTotal?.["budgetTotals"][el.value].totals ||
                {
                  totalInBRL: 0,
                  totalInCurrency: 0,
                }
              }
              taxTotal={
                // budget.budgetValuesTotal?.["budgetTotals"][el.value].taxes ||
                {
                  totalInBRL: 0,
                  totalInCurrency: 0,
                }
              }
              isSelected={false}
              onSelect={function (): void {}}
            />
          );
        })}
        <Box display={"flex"}>
          <Box ml={"auto"} p={1}>
            <ActionSelect
              label="Ações"
              actionOptions={actionOptions}
              triggerAction={triggerAction}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default BudgetSummary;
