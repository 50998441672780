import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { IoMdClose, IoMdInformationCircleOutline } from "react-icons/io";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../Input";
import { FaSearch } from "react-icons/fa";
import ReactInputMask from "react-input-mask";
import ProjectList from "./ProjectList/ProjectList";
import { regexEmail } from "../../../../utils/utils";
import RequestingService from "../../../../services/requesting.service";
import { useMutation, useQueryClient } from "react-query";
import { NotificationFields } from "../../../../types/notification";
import Notification from "../../../UI/Notification";

interface RequesterModalProps {
  isOpen: boolean;
  onClose: () => void;
  requesterData: any;
}

const schema = object({
  name: string().required("Nome é obrigatório"),
  email: string().matches(regexEmail).required("Email é obrigatório"),
  phone: string().min(14).required("Telefone é obrigatório"),
}).required();

const EditRequesterModal = ({
  isOpen,
  onClose,
  requesterData,
}: RequesterModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: requesterData?.name || "",
      email: requesterData?.email || "",
      phone: requesterData?.phone || undefined,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const queryClient = useQueryClient();

  const [notification, setNotification] = useState<NotificationFields>({
    open: false,
    severity: "success",
    message: "",
  });
  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  async function onSubmit(form: any) {
    updateRequester({
      ...form,
      client: requesterData?.client,
    });
  }

  const { mutate: updateRequester, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => RequestingService.update(requesterData?._id, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("requesters-data");
        onClose();
      },
      onError: (error) => {
        setNotification({
          open: true,
          severity: "error",
          message: "Não foi possível salvar",
        });
      },
    }
  );

  useEffect(() => {
    reset({
      name: requesterData?.name || "",
      email: requesterData?.email || "",
      phone: requesterData?.phone || undefined,
    });
  }, [requesterData]);

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={"md"}>
      <Notification
        notification={notification}
        closeNotification={closeNotification}
      />
      <DialogTitle
        sx={{ m: 0, px: 4, paddingTop: 3, color: "#4B465C", fontSize: 24 }}
      >
        Dados do solicitante
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#E8F2FC",
            margin: "8px 26px",
            borderRadius: 2,
            padding: "20px 18px",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IoMdInformationCircleOutline color="#1976D2" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 14 }}>
              1. Não abrevie nomes para evitar homônimos
            </span>
            <span style={{ fontSize: 14 }}>
              2. Email com domínio de empresa se possível
            </span>
          </div>
        </Box>
        <DialogContent sx={{ px: 4, marginTop: -4 }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box flexDirection={"column"}>
              <Input label="Nome" {...register("name")} error={!!errors.name} />
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={2}
                alignItems={"center"}
              >
                <Input
                  label="Email"
                  {...register("email")}
                  error={!!errors.email}
                />
                <Controller
                  control={control}
                  name="phone"
                  //defaultValue={"123123"}
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <ReactInputMask
                      // @ts-ignore
                      maskPlaceholder={null}
                      mask="(99) 99999-9999"
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                    >
                      <Input
                        label="Telefone"
                        placeholder="(00) 0 0000 0000"
                        error={!!errors.phone}
                        defaultValue={requesterData?.phone}
                      />
                    </ReactInputMask>
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Box
                width={"100%"}
                borderBottom={"1px solid #989898"}
                marginTop={2}
              >
                <span>Projetos relacionados</span>
              </Box>
              {/* <Box display={'flex'} flexDirection={'column'} width={'50%'} m={0} p={0}>
                <Input placeholder=" Buscar nome ou email" InputProps={{
                  endAdornment: <InputAdornment position="end" ><FaSearch /> </InputAdornment>
                }} />
              </Box> */}
            </Box>
            <ProjectList requesterId={requesterData?._id} />
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 7, py: 4 }}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            sx={{ fontSize: 16, color: "#1361A4" }}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            disabled={isLoadingUpdate}
            sx={{ fontSize: 16, backgroundColor: "#1361A4" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditRequesterModal;
