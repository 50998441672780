import { Box } from "@mui/material";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { HeaderOptions } from "../../../../types/budget/budget";

type Props = {
  type: HeaderOptions;
  isActive: boolean;
  setIsActive: (type: HeaderOptions) => void;
};

export default function CalcOption({ type, isActive, setIsActive }: Props) {
  return (
    <Box
      width={"40px"}
      height={"40px"}
      borderRadius={1}
      m={0.25}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      fontSize={"16px"}
      fontWeight={"bold"}
      style={
        isActive
          ? { background: "#1976D2", color: "white", cursor: "pointer" }
          : { background: "#f3f3f3", color: "#222", cursor: "pointer" }
      }
      onClick={() => setIsActive(type)}
    >
      {type === "budgetTotals" ? <BsFileEarmarkSpreadsheet style={{strokeWidth: .7}} /> : type}
    </Box>
  );
}
