import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import { LabeledInput } from "../../../UI/LabeledInput";
import ChangelingMonetaryInput from "../../../UI/ChangelingMonetaryInput";
import CoinData from "../../../../utils/Common-Currency.json";
import { useMemo, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import {
  ExchangeData,
} from "../../../../types/budget/budget";
import { toast } from "react-toastify";
type Props = {
  savedExchangeData?: ExchangeData;
  compareExchangeData?: ExchangeData;
  onSubmitExchange: (exchangeData: ExchangeData) => void
};

const Exchange: React.FC<Props> = ({
  savedExchangeData,
  onSubmitExchange
}: Props) => {
  const [exchangeState, setExchangeState] = useState({
    date: "",
    currency: "",
    value: 0,
  });


  function handleChange(value: any, field: string) {
    if (field === "monetary-change") {
      return setExchangeState({
        ...exchangeState,
        currency: value.currency,
        value: value.value,
      });
    }
    setExchangeState({ ...exchangeState, [field]: value });
  }
  function updateCalcValues(type: string, value: number, calcType: string) {
    handleChange(
      {
        currency: calcType,
        value,
      },
      "monetary-change"
    );
  }

  const coinOptions = CoinData.map((el) => {
    return { value: el.code, label: el.name };
  });
  useMemo(() => {
    if (!!savedExchangeData)
      setExchangeState({...savedExchangeData});
  }, [savedExchangeData]);

  function submitExchange() {
    onSubmitExchange(exchangeState)
    toast.success('Câmbio salvo com sucesso!')
  }

  return (
    <Accordion
      sx={{ my: 1.5, borderRadius: "4px" }}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<BiChevronDown />}
        id="panel1a-header"
        sx={{
          minHeight: 40,
          maxHeight: 40,
          borderRadius: "4px",
          "&.Mui-expanded": {
            minHeight: 40,
            maxHeight: 40,
          },
        }}
      >
        <span style={{ fontWeight: "bold" }}>Câmbio</span>
      </AccordionSummary>
      <Box>{/* total summary */}</Box>

      <AccordionDetails>
        <Box display={"flex"} gap={3}>
          <LabeledInput
            value={exchangeState.date}
            onChange={(e) => handleChange(e.target.value, "date")}
            type="date"
            label="Data cotação"
          />
          <ChangelingMonetaryInput
            calculusOptions={coinOptions}
            prevValue={{
              calcType: exchangeState.currency,
              value: exchangeState.value,
            }}
            disabled={false}
            label={"Moeda / valor dia"}
            type={""}
            updateCalcValues={updateCalcValues}
          />
        </Box>
        <Box display={"flex"}>
          <Button
            onClick={submitExchange}
            sx={{ ml: "auto" }}
            variant="outlined"
          >
            Salvar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Exchange;
