import {ReactComponent as Subscription} from "./subscription.svg";
import {ReactComponent as AebImg} from "./food_and_drinks.svg";
import {ReactComponent as AerialImg} from "./air.svg";
import {ReactComponent as ComunicationImg} from "./comunication.svg";
import {ReactComponent as EquipmentImg} from "./equipments.svg";
import {ReactComponent as RSVPImg} from "./rsvp.svg";
import {ReactComponent as SupportImg} from "./support.svg";
import {ReactComponent as TotalsImg} from "./totals.svg";
import {ReactComponent as TransferImg} from "./transfer.svg";
import {ReactComponent as TranslationImg} from "./translation.svg";
import {ReactComponent as AcommodationImg} from "./acommodations.svg";
import {ReactComponent as OthersImg} from "./several.svg";

interface StringMap { [key: string]: any; }
export const BudgetIcons: StringMap = {
  ACCOMMODATIONS: <AcommodationImg/>,
  ROOMS: <Subscription/>,
  AEB: <AebImg/>,
  EQUIPMENTS: <EquipmentImg/>,
  TRANSLATIONS: <TranslationImg/>,
  SUPPORT: <SupportImg/>,
  SUBSCRIPTION: <Subscription/>,
  RSVP: <RSVPImg/>,
  AIR: <AerialImg/>,
  TRANSFER: <TransferImg/>,
  COMMUNICATIONS: <ComunicationImg/>,
  SEVERAL: <OthersImg/>,
  TOTALS: <TotalsImg/>
}