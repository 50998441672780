import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { InputDiv, SummaryDiv, RadioDiv, InputRow, StyledBox } from "../styles";
import { useEffect } from "react";
import { Input } from "../../../Input";
import { OthersFieldType, RsvpType } from "../../../../types/rsvp/index";
import { FormProvider, useForm } from "react-hook-form";
import { useRsvp } from "../../../../contexts/rsvp.context";

type Props = {
  onSubmitNav: () => void
  isDisabled: boolean
}

export function Others({onSubmitNav, isDisabled}: Props) {
  const { rsvpId, updateOthers, createRsvp, others } = useRsvp()
  const obj: OthersFieldType = {
    rsvpReport: "",
    detailsToAskForObs: "",
    costumeObs: ""
  }
  const methods = useForm({
    defaultValues: obj,
  })
  const { handleSubmit, register, watch, setValue, reset } = methods

  function onSubmit(form: OthersFieldType) {
    rsvpId ? updateOthers(form) : createRsvp({ others: form } as RsvpType)
    onSubmitNav()
  }

  useEffect(() => {
    if (others) {
      reset(others);
    }
  }, [others]);



  return (<FormProvider {...methods}>
    
    <StyledBox  onSubmit={handleSubmit(onSubmit)} >
      <h3>8.Outros</h3>
      <InputDiv>
        <SummaryDiv>
          <strong>Detalhe para perguntar antes de RSVP</strong>
        </SummaryDiv>
        <RadioDiv>
          <Input
            fullWidth
            label="Outros"
            multiline
            size="small"
           {...register('detailsToAskForObs')}
          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Traje ou tamanho de camiseta</strong>
        </SummaryDiv>
        <RadioDiv>
          <Input
            fullWidth
            label="Outros"
            type="text-area"
            multiline
            size="small"
            {...register('costumeObs')}

          />
        </RadioDiv>
      </InputDiv>
      <InputDiv>
        <SummaryDiv>
          <strong>Relatório RSVP tradicional (planilha/tabela)</strong>
          <span>Definir frequencia de envio</span>
        </SummaryDiv>
        <RadioDiv>
          <RadioGroup
            value={watch("rsvpReport")}
            onChange={(e) => setValue("rsvpReport", e.target.value)}
          >
            <FormControlLabel
              value="NO_SERVICE_USAGE_FOR_COMPANIONS"
              control={<Radio />}
              label="Acompanhantes não podem usufruir do serviço (conforme padrão de compliance)"
            />
          </RadioGroup>
        </RadioDiv>
      </InputDiv>

      <br />

      <Button
        variant="contained"
        
        disabled={isDisabled}
        style={{ position: 'relative', left: '90%', top: '30%' }}
        type="submit"
      >
        Salvar
      </Button>
    </StyledBox>
    </FormProvider>
  );
}
