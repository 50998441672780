import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Divider,
} from "@mui/material";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import ItemValue from "./ItemValue";
import { RadioGroup } from "../../../../UI/RadioGroup";
import { formatCurrency } from "../../../../../utils/formatters";
import { ServiceData } from "../../../../../types/budget/service-item";
import { BudgetItemTypeEnum, Totals } from "../../../../../types/budget/budget";
import { add, format } from "date-fns";

type BudgetItemProps = {
  type: keyof typeof BudgetItemTypeEnum;
  servicesArr: any[];
  total: Totals;
  taxTotal: Totals;
  isSelected: boolean;
  selectable: boolean;
  onSelect: () => void;
};

const dummyArr = ["", "", ""];
const BudgetItem = ({
  type,
  servicesArr,
  total,
  selectable,
  taxTotal,
}: BudgetItemProps) => {
  return (
    <Accordion
      sx={{
        boxShadow: "none",
        my: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<BiChevronDown />}
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: 40,
          maxHeight: 40,
          "&.Mui-expanded": {
            minHeight: 40,
            maxHeight: 40,
          },
        }}
      >
        <Box>
          {selectable ? (
            <RadioGroup.Item
              label={`${servicesArr.length}x ${type}`}
              value="false"
            />
          ) : (
            <> {`${servicesArr.length}x ${BudgetItemTypeEnum[type]}`}</>
          )}
        </Box>{" "}
        <Box ml={"auto"} my={"auto"}>
          {formatCurrency(total.totalInBRL)}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ fontSize: "14px", lineHeight: "1.5" }}>
        {servicesArr.map((el, index) => {
          return <BudgetService serviceData={el} index={index} />;
        })}

        <Divider />
        {/* <br /> */}
        {/* <ItemValue total={taxTotal.totalInBRL} savings={0} title="Taxas" /> */}
        <br />
        <ItemValue total={total.totalInBRL} savings={0} title="Total" />
      </AccordionDetails>
    </Accordion>
  );
};

type BudgetServiceProps = {
  serviceData: ServiceData;
  index: number;
};
function formatDate(date: string) {
  return !isNaN(new Date(date).getTime())
    ? format(add(new Date(date), { hours: 4 }), "dd/MM/yyyy")
    : "data não definida";
}
const BudgetService = ({ serviceData, index }: BudgetServiceProps) => {
  return (
    <Box mb={1} px={1.5}>
      {index + 1} - {serviceData.supplierName?.slice(0, 20) || ""} <br />{" "}
      {serviceData.budgetValues.observation}
      <br /> {formatDate(serviceData.budgetValues.inputDate)} -{" "}
      {formatDate(serviceData.budgetValues.outDate)} (
      {serviceData.budgetValues.daily} dias) <br /> Quantidade:{" "}
      {serviceData.budgetValues.quantity} <br />
      NFMM: {serviceData.calcOption} <br />
      Total: {formatCurrency(serviceData.budgetValues.totals.totalInBRL || 0)}
      <br />
    </Box>
  );
};

export default BudgetItem;
