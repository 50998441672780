import { Box } from "@mui/material";
import ActionSelect from "../ActionSelect";
import { useState } from "react";
import EditRequesterModal from "../../EditRequesterModal";
import DeleteRequesterModal from "../../DeleteRequesterModal";

type Props = {
  requesterData: {
    _id?: string;
    name: string;
    email: string;
    phone: string;
    client: string;
  };
};
const RequesterSummary: React.FC<Props> = ({ requesterData }: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  function triggerAction(action: string) {
    if (action === "edit") {
      setShowEditModal(true);
    }
  }

  return (
    <Box m={1}>
      <EditRequesterModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        requesterData={requesterData}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #E0E0E0"}
        padding={1}
      >
        <Box sx={{ display: "flex" }}>
          <Box flexDirection={"column"} display={"flex"} gap={0.5}>
            <span> {requesterData.name} </span>
            <div
              style={{
                color: "#667085",
                fontSize: "15px",
                maxWidth: 200,
                overflowWrap: "break-word",
              }}
            >
              {requesterData.email}
            </div>

            <span style={{ color: "#667085", fontSize: "15px" }}>
              {" "}
              {requesterData.phone}{" "}
            </span>
          </Box>
        </Box>
        <ActionSelect triggerAction={triggerAction} minWidth={0} />
      </Box>
    </Box>
  );
};

export default RequesterSummary;
