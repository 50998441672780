import {
  Box,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  Card,
  Slide,
  Divider,
  Button,
} from "@mui/material";
import { VerticalTab } from "../../VerticalTabs";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import RightSideBar from "./RightSideBar";
import { useBudget } from "../../../contexts/BudgetContext/projectbudgets.context";
import { createOptionsFromEnum } from "../../../utils/formatters";
import { BudgetItemTypeEnum, BudgetStatusEnum } from "../../../types/budget/budget";
import { BudgetIcons } from "../../../assets/styled-icons/budget";
import BudgetTotals from "./BudgetTotals/BudgetTotals";
import YesOrNoSwitch from "../../UI/YesOrNoSwitch";
import LeftSideBarHeader from "./LeftSideBarHeader";
import {
  BsFillCaretLeftSquareFill,
  BsFillCaretRightSquareFill,
} from "react-icons/bs";
import BudgetSupplier from "./BudgetSupplier";
import useSize from "../../../utils/hooks";
import ImportModal from "./ImportModal";
import { useProject } from "../../../contexts/project.context";

const itemTypeOption = createOptionsFromEnum(BudgetItemTypeEnum);

const BudgetStep = () => {
  const windowSize = useSize();
  const { actionOptions } = useContext(AuthContext);
  const {
    notification,
    loadingBudget,
    activeBudget,
    budgetList,
    activeCalcOption,
    closeNotification,
    clearBudget,
    activeBudgetDispatch,
    createEmptyBudget,
    setActiveCalcOption,
    loadBudgetList,
    negotiationVersion,
  } = useBudget();
  const { tabActive } = useProject();

  const [collapseSideBar, setCollapseSideBar] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  const options = itemTypeOption.map((el: any, index: number) => {
    if (index === itemTypeOption.length - 1)
      return {
        title: el.label,
        value: el.value,
        description: "",
        icon: BudgetIcons[el.value],
        template: <BudgetTotals />,
      };
    return {
      title: el.label,
      value: el.value,
      description: "",
      icon: BudgetIcons[el.value],
      template: <></>,
    };
  });

  useEffect(() => {
    if (projectId) {
      loadBudgetList(projectId);
    }
    return () => {
      clearBudget();
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] < 1550) setCollapseSideBar(true);
    if (windowSize[0] > 1540) setCollapseSideBar(false);
  }, [windowSize]);

  function renderCollapseOption(collapse: boolean) {
    return collapse ? (
      <BsFillCaretLeftSquareFill
        color="#1361A4"
        size={22}
        cursor={"pointer"}
        onClick={() => setCollapseSideBar(!collapseSideBar)}
      />
    ) : (
      <BsFillCaretRightSquareFill
        color="#1361A4"
        size={22}
        cursor={"pointer"}
        onClick={() => setCollapseSideBar(!collapseSideBar)}
      />
    );
  }

  function renderBudgetInfo() {
    const activeBudgetIndex =
      budgetList?.findIndex((el) => el._id === activeBudget._id) || 0;
    return !budgetList || budgetList.length < 1 ? (
      <Box
        display={"flex"}
        margin={"auto"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <span style={{ fontSize: "20px", fontWeight: "semi-bold" }}>
          Crie um orçamento para começar
        </span>
        <Box gap={2} display={"flex"} justifyContent={"space-evenly"}>
          <Button
            onClick={createEmptyBudget}
            sx={{
              fontSize: "14px",
              mt: 2,
              p: 1,
            }}
          >
            Novo orçamento
          </Button>
          {/* <Button
            variant="outlined"
            onClick={() => setShowImportModal(true)}
            sx={{
              fontSize: "14px",
              mt: 2,
              p: 1,
            }}
          >
            Importar de logística
          </Button> */}
        </Box>
      </Box>
    ) : activeCalcOption !== "budgetTotals" ? (
      <Box width={"100%"}>
        <Box pb={2}>
          <span style={{ fontSize: "20px", fontWeight: "semi-bold" }}>
            Categoria {activeCalcOption} - Orçamento {activeBudgetIndex + 1} {tabActive === 9 ? `- Negociação ${negotiationVersion}` : ` - ${BudgetStatusEnum[activeBudget.status || "OPEN"]}`}
          </span>
        </Box>
        {activeBudget?.supplierList?.map((el, index) => {
          if (el.calcOption === activeCalcOption)
            return <BudgetSupplier supplierId={el.supplierId} key={index} />;
          else return <></>;
        })}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            onClick={() => {
              activeBudgetDispatch({
                type: "ADD_SUPPLIER",
                payload: { calcOption: activeCalcOption },
              });
            }}
            sx={{
              fontSize: "14px",
              mt: 2,
              px: 1,
            }}
            variant="outlined"
            size="small"
          >
            Novo fornecedor
          </Button>

          <Button
            sx={{
              fontSize: "14px",
              mt: 2,
              px: 1,
            }}
            size="small"
          >
            Salvar
          </Button>
        </Box>
      </Box>
    ) : (
      <BudgetTotals />
    );
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pb={4}
      bgcolor={"#F9FCFF"}
      flex={1}
      margin={"auto"}
      width={"100%"}
      overflow="auto"
      borderTop={"1px solid #eee"}
    >
      {showImportModal && (
        <ImportModal
          isOpen={showImportModal}
          onClose={() => setShowImportModal(false)}
          area={""}
          label={""}
        />
      )}

      {notification?.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          open={notification.open}
          onClose={closeNotification}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert
            onClose={closeNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}

      {loadingBudget ? (
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          py={10}
          mt={10}
        >
          <Typography variant={"h6"}>Carregando...</Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Card
          sx={{
            display: "flex",
            boxShadow: "0px 4px 18px 0px #4B465C1A",
          }}
        >
          <VerticalTab.Root>
            <LeftSideBarHeader
              onActiveOptionChange={(type) => setActiveCalcOption(type)}
            />
            <Divider />
            {options.map((el: any, index: any) => (
              <VerticalTab.Item key={index}>
                <VerticalTab.Icon
                  icon={el.icon}
                  isActive={false}
                  color={
                    activeBudget &&
                    activeBudget?.itemTypeList?.[activeCalcOption]?.[
                      el.value
                    ] === true
                      ? "#1361A4"
                      : activeBudget?.itemTypeList?.[activeCalcOption]?.[
                          el.value
                        ] === false
                      ? "#EFDBDB"
                      : undefined
                  }
                />
                <VerticalTab.Content
                  title={el.title}
                  description={el.description}
                />
                <Box display="flex" flex={1} justifyContent={"flex-end"}>
                  <YesOrNoSwitch
                    type={el.value}
                    value={
                      activeBudget?.itemTypeList?.[activeCalcOption]?.[el.value]
                    }
                    setValue={(type: string, newValue: boolean) => {
                      activeBudgetDispatch({
                        type: "YES_NO_SWITCH_CHANGE",
                        payload: { type, newValue, activeCalcOption },
                      });
                    }}
                  />
                </Box>
              </VerticalTab.Item>
            ))}
          </VerticalTab.Root>

          <Box
            display={"flex"}
            width={"100%"}
            sx={{ background: "transparent", m: "0 auto" }}
          >
            <Box
              width={!collapseSideBar ? "73%" : "100%"}
              margin={"24px"}
              display={"flex"}
              justifyContent={"center"}
            >
              {renderBudgetInfo()}
            </Box>
            <Box
              display={"flex"}
              justifyContent={collapseSideBar ? "flex-start" : "flex-end"}
              marginLeft={"auto"}
              width={collapseSideBar ? "20px" : "400px"}
              ml={1}
              zIndex={999}
            >
              <Box marginLeft={"auto"} py={1}>
                {renderCollapseOption(collapseSideBar)}
              </Box>
              <Box
                zIndex={999}
                display={collapseSideBar ? "none" : "block"}
                bgcolor={"#FFF"}
                sx={{
                  border: "1px solid #ddd",
                  borderTop: "none",
                  p: 1,
                }}
              >
                <RightSideBar />
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </Box>
  );
};
export default BudgetStep;
