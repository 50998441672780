export const initialItemTypeList = {
    A: {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
    },
    B: {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
    },
    C: {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
    },
    D: {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
    },
    E: {
      ACCOMMODATIONS: undefined,
      ROOMS: undefined,
      AEB: undefined,
      EQUIPMENTS: undefined,
      TRANSLATIONS: undefined,
      SUPPORT: undefined,
      SUBSCRIPTION: undefined,
      RSVP: undefined,
      AIR: undefined,
      TRANSFER: undefined,
      COMMUNICATIONS: undefined,
      SEVERAL: undefined,
    },
    budgetTotals: undefined,
  };
  