import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FaEye,
  FaFile,
  FaFileAlt,
  FaFileUpload,
  FaPencilAlt,
  FaRegFile,
  FaSave,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import FileUploadService from "../../../services/fileUpload.service";

interface DragAndDropProps {
  fileData?: any;
  formats?: string[];
  title: string;
  projectId: string;
  area: string;
  index: number;
  onFileUpload?: (files: any) => void;
  onRemoveFile: (index: number) => void;
}

export default function MinimalDragAndDrop({
  fileData,
  projectId,
  area,
  formats,
  onFileUpload,
  onRemoveFile,
  index
}: DragAndDropProps) {
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [tempFileName, setTempFileName] = useState<string | undefined>(
    undefined
  );
  const [isEditing, setIsEditing] = useState(false);
  const drop = React.useRef<null | HTMLDivElement>(null);
  const input = React.useRef<null | HTMLInputElement>(null);

  const { mutate: createFile, isLoading: isLoadingCreate } = useMutation(
    (data: any) => FileUploadService.create(data),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  const { mutate: updateFile, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => FileUploadService.update("", data),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
    }
    return () => {
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
      }
    };
  }, []);
  useEffect(() => {
    if (!!fileData) {
      setFile(fileData);
      setFileName(fileData.fileName);
      setTempFileName(fileData.fileName);
    }
  }, [fileData]);
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer?.files || []);
    setFileName(e.dataTransfer?.files?.[0].name);
    setTempFileName(e.dataTransfer?.files?.[0].name);

    if (validateFiles(files)) onUpload(files);
  };
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);

    if (validateFiles(files)) {
      setTempFileName(files[0].name);
      setFileName(files[0].name);
      onUpload(files);
    }
  };
  function handleDelete() {
   onRemoveFile(index)
  }
  function handleEdit() {
    setIsEditing(!isEditing);
  }

  function handleSetFileName() {
    setFileName(tempFileName);
    setFile({ ...file, name: tempFileName });
    setIsEditing(false);
  }
  function handleCancel() {
    setIsEditing(false);
    setTempFileName(fileName);
  }
  function validateFiles(files: File[]) {
    if (!files) return false;
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      toast.warn(`Formato não permitido, use um listado a seguir: ${formats}`);
      return false;
    }
    return true;
  }
  async function handleDownload() {
    // const newFile = {...file.fileBlob, name: fileName}
    // const blob = new Blob([newFile], { type: newFile.type });
    // let reader = new FileReader();
    // let src = URL.createObjectURL(blob);
    // reader.readAsDataURL(blob);

    // reader.onload = function () {
    //   window.open(file.src, "_blank");

    // }
    window.open(file.src, "_blank");
  }
  async function onUpload(files: File[]) {
    let reader = new FileReader();
    let src = URL.createObjectURL(files[0]);
    reader.readAsDataURL(files[0]);

    reader.onload = function () {
      if (onFileUpload) {
        onFileUpload({
          ...file,
          fileName: files[0].name,
          fileBlob: files[0],
          projectId,
          area,
          src,
        });
        setFileName(undefined);
        setTempFileName(undefined);
      }
    };

    reader.onerror = function () {};
  }
  const endAdornment = () => {
    return isEditing ? (
      <InputAdornment
        sx={{
          gap: 1,
          cursor: "pointer",
          fontSize: "0.8rem",
          justifyContent: "center",
        }}
        position="end"
      >
        <FaSave color="#1361A4" onClick={handleSetFileName} />
        <MdOutlineCancel color="#E8595A" onClick={handleCancel} />
      </InputAdornment>
    ) : (
      <InputAdornment
        sx={{ gap: 1, cursor: "pointer", fontSize: "0.9rem" }}
        position="end"
      >
        <FaEye color="#757575" onClick={handleDownload} />
        <FaPencilAlt color="#757575" onClick={handleEdit} />
        <FaTrashAlt color="#D32F2F" onClick={handleDelete} />
      </InputAdornment>
    );
  };

  useEffect(() => {
    async function handleUploadApi() {
      const formData = new FormData();

      if (!!file?._id && !!file?.data64) {
      } else if (!!file?.file) {
        let blob = await fetch(file.file, { mode: "no-cors" }).then((r) =>
          r.blob()
        );
        formData.append("file", blob);
        formData.append("data", JSON.stringify(file));
        createFile(formData);
      }
    }
    handleUploadApi();
  }, [file]);

  return fileName !== undefined ? (
    <TextField
      size="small"
      variant={isEditing ? "outlined" : "standard"}
      disabled={!isEditing}
      onChange={(e) => setTempFileName(e.target.value)}
      InputProps={{
        endAdornment: endAdornment(),
        startAdornment: (
          <FaRegFile
            color="#1976D2"
            style={{ marginRight: "5px", marginTop: "-2px" }}
          />
        ),
        disableUnderline: true,
      }}
      sx={{
        "& input:disabled": {
          border: 0,
        },
        "& .MuiTextField-root": {
          border: 0,
        },
        background: isEditing ? "transparent" : "#eee",
        px: 2,
        py: 0.5,
        borderRadius: 1,
        width: "95%",
      }}
      value={tempFileName}
    />
  ) : (
    <div
      style={{ cursor: "pointer" }}
      ref={drop}
      onClick={() => {
        input.current?.click();
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      >
        <FaFileUpload color="#1976D2" />
        &nbsp;{" "}
        <span style={{ color: "#A2A2A2", alignItems: "center" }}>
          Arraste um arquivo ou
        </span>{" "}
        &nbsp;{" "}
        <span style={{ color: "#1976D2", alignItems: "center" }}>
          {" "}
          clique para enviar
        </span>
      </div>
      <input
        ref={input}
        type="file"
        style={{ display: "none" }}
        accept={
          formats ? formats.map((format) => `.${format}`).join(", ") : undefined
        }
        multiple={false}
        onChange={handleSelectFile}
      />
    </div>
  );
}
