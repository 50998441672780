import { Box, Button } from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useQuery } from "react-query";
import { Column } from "react-table";

import TableComponent from "../../../CustomDataGrid";

type PropTypes = {
  data: any[];
};
const EXCLUDED_FIELDS = [
  "Criado em",
  "Atualizado em",
  "createdAt",
  "updatedAt",
  "_id",
  "Briefing",
  "__v",
  "deletedAt",
  "Deletado em",
  "Último usuário que atualizou",
];

const ChangesList: React.FC<PropTypes> = ({ data }: PropTypes) => {
  const [auditData, setAuditData] = useState([] as any[]);

  useEffect(() => {
    if (data.length > 0) {
      // data = data.filter(el => !!el.oldData)
      data = data.map((el) => {
        if (!el)
          return {
            fields: "---",
            userName: "---",
            date: "---",
          };
        const splited = el.date.split("T");
        const dateParts = splited[0] ? splited[0].split("-") : null;
        const hourParts = splited[1] ? splited[1].split(":") : null;
        el.fields = el.data ? Object.keys(el.data).length : 0;
        el.userName = el.user.name || "--";
        el.date =
          hourParts && dateParts
            ? `${dateParts[2]}/${dateParts[1]}/${dateParts[0]} às ${hourParts[0]}:${hourParts[1]}`
            : "---";
        return el;
      });
      setAuditData(data);
    } else setAuditData([]);
  }, [data]);
  interface IAuditRow {
    action: string;
    local: string;
    fields: number;
    user: any;
    userName: string;
    date: string;
  }

  const [rowExpanded, setRowExpanded] = useState(-1);
  function handleExpandRow(index: number) {
    setRowExpanded(index === rowExpanded ? -1 : index);
  }

  interface StringMap {
    [key: string]: any;
  }
  const actionDict: StringMap = {
    Update: "Atualização",
    Create: "Criação",
    Delete: "Deleção",
  };
  const oldInfo = (cell: any) => {
    const newValues: StringMap = cell?.row?.original?.oldData;

    const content: any[] = [];
    if (!newValues)
      return (
        <Box
          sx={{ ml: -6, fontSize: ".9rem", padding: 1, background: "#FDFDFD" }}
        >
          <span style={{ color: "#A9A8AE" }}>Anterior</span>
          <Box>
            <span style={{ color: "#4B465C" }}>Lista vazia</span>
          </Box>
        </Box>
      );
    for (const [key, value] of Object.entries(newValues)) {
      if (
        (typeof value === "string" && value.trim() === "") ||
        EXCLUDED_FIELDS.includes(key) ||
        value === undefined ||
        value === null
      )
        continue;
      const rendered = typeof value === "object" ? value.Nome : value;
      content.push(
        <Box margin={"8px 0px"}>
          <span style={{ color: "#A9A8AE" }}>{key}</span>
          <br />
          <span style={{ color: "#4B465C" }}>
            {typeof rendered === "object"
              ? "Objeto não traduzido devidamente"
              : rendered}
          </span>
        </Box>
      );
    }
    return (
      <Box
        sx={{ ml: -6, fontSize: ".9rem", padding: 1, background: "#FDFDFD" }}
      >
        <span style={{ color: "#A9A8AE" }}>Anterior</span>
        <Box>{content.map((el) => el)}</Box>
      </Box>
    );
  };

  const newInfo = (cell: any) => {
    const newValues: StringMap = cell?.row?.original?.data;
    const content: any[] = [];
    if (newValues) {
      for (const [key, value] of Object.entries(newValues)) {
        if (
          (typeof value === "string" && value.trim() === "") ||
          EXCLUDED_FIELDS.includes(key) ||
          value === undefined ||
          value === null
        )
          continue;

        const rendered = typeof value === "object" ? value.Nome : value;
        content.push(
          <Box margin={"8px 0px"}>
            <span style={{ color: "#A9A8AE" }}>{key}</span>
            <br />
            <span style={{ color: "#4B465C" }}>
              {typeof rendered === "object"
                ? "Objeto não traduzido devidamente"
                : rendered}
            </span>
          </Box>
        );
      }
    }
    return (
      <Box
        sx={{ ml: -6, fontSize: ".9rem", padding: 1, background: "#FDFDFD" }}
      >
        <span style={{ color: "#A9A8AE" }}>Nova informação</span>
        <Box>{content.map((el) => el)}</Box>
      </Box>
    );
  };

  const cellExpanded = (value: any) => (
    <span
      style={{
        maxHeight: "80px",
      }}
    >
      {" "}
      {value}{" "}
    </span>
  );

  const columns: Column<IAuditRow>[] = [
    {
      Filter: "",
      accessor: "action",
      Header: "#",
      id: "counter",
      Cell: ({ cell, row }) => {
        return row.index === rowExpanded ? (
          cellExpanded(
            <span
              onClick={() => {
                handleExpandRow(cell.row.index);
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              <FaCaretUp /> {`${cell.row.index + 1}`}{" "}
            </span>
          )
        ) : (
          <span
            onClick={() => {
              handleExpandRow(cell.row.index);
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <FaCaretDown /> {`${cell.row.index + 1}`}{" "}
          </span>
        );
      },
      width: 20,
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "action",
      Header: "Tipo de operação",
      id: "action",
      Cell: ({ cell, row }) => {
        return row.index === rowExpanded ? (
          <>
            <span>
              {actionDict[cell.row.original.action] || cell.row.original.action}
            </span>
            {oldInfo(cell)}
          </>
        ) : (
          <span>
            {" "}
            {`${
              actionDict[cell.row.original.action] || cell.row.original.action
            }`}{" "}
          </span>
        );
      },
      width: 120,
      disableSortBy: true,
    },
    // {
    //   Filter: "",
    //   accessor: "local",
    //   Header: "Local",
    //   Cell: ({ cell, row }) => {
    //     return row.index === rowExpanded ?
    //       <>
    //         <span>{cell.row.original.local}</span>
    //         {newInfo(cell)}
    //       </>
    //       : <span >  {`${cell.row.original.local}`} </span>
    //   },
    //   width: 120,
    //   disableSortBy: true,
    // },
    {
      Filter: "",
      accessor: "fields",
      Header: "Campos",
      Cell: ({ cell, row }) => {
        return row.index === rowExpanded ? (
          <>
            <span>{cell?.value}</span>
            {newInfo(cell)}
          </>
        ) : (
          <span> {`${cell?.value}`} </span>
        );
      },
      width: 30,
      disableSortBy: true,
    },
    {
      Filter: "",
      accessor: "userName",
      Header: "Realizado por",
      Cell: ({ cell, row }) =>
        row.index === rowExpanded ? (
          cellExpanded(cell.value)
        ) : (
          <span> {`${cell.value}`} </span>
        ),
      width: 90,
      disableSortBy: true,
    },

    {
      Filter: "",
      accessor: "date",
      Header: "Registrado em",
      Cell: ({ cell, row }) =>
        row.index === rowExpanded ? (
          cellExpanded(cell.row.original.date)
        ) : (
          <span> {`${cell.row.original.date}`} </span>
        ),
      width: 90,
      disableSortBy: true,
    },
  ];


  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      py={2}
      px={1}
      maxHeight={"400px"}
      bgcolor={"#F9FCFF"}
    >
      <TableComponent
        columns={columns}
        data={auditData || []}
        isLoading={false}
        total={0}
      />
    </Box>
  );
};
export default ChangesList;
