import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  TextFieldVariants,
} from "@mui/material";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";

type Props = {
  prevValue: {
    calcType: string;
    value: number;
  };
  label: string;
  type: string;
  calculusOptions: keyValueObj[];
  helperText?: string;
  disabled: boolean;
  updateCalcValues(type: string, value: number, calcType: string): void;
};
type keyValueObj = {
  value: string;
  label: string;
};

const ChangelingMonetaryInput: React.FC<Props> = ({
  prevValue,
  label,
  type,
  calculusOptions,
  updateCalcValues,
  helperText,
  disabled,
}: Props) => {
  const [value, setValue] = useState(0);
  const [calcType, setCalcType] = useState(type === "ISS" ? "PERCENTAGE" : "");

  const sxOptions = {
    boxShadow: "none",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none solid none none",
      borderColor: "#777",
      borderWidth: "1px",
      borderRadius: 0,
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderStyle: "none solid none none",
    },
    width: "100%",
  };
  const renderMenuOptions = (typeOptions: keyValueObj[]) => {
    return typeOptions.map((option) => (
      <MenuItem value={option.value}>{option.label}</MenuItem>
    ));
  };
  const handleChange = async (v: any) => {
    if (value === v.floatValue) return;
    if (v.floatValue) {
      setValue(parseFloat(v.floatValue));
      // Set the value to value * 100 because it was divided on the field value prop
      // onChange && onChange({ ...v, floatValue: v.floatValue / 100 });
    } else {
      // setValue("");
      // onChange && onChange({ ...v, floatValue: undefined });
    }
  };
  const handleBlur = () => {
    if (calcType === "" || value === 0) return;
    updateCalcValues(type, value, calcType);
  };
  useMemo(() => {
    if (!!prevValue.calcType) setCalcType(prevValue.calcType);
    if (!!prevValue.value) setValue(prevValue.value);
  }, [prevValue]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={"100%"}
      my={1}
    >
      <InputLabel sx={{ fontSize: 13 }}>{label}</InputLabel>
      {type !== "NFMM" ? (
        <NumberFormat
          sx={{
            background: disabled ? "#eee" : "#FFF",
            "& .MuiInputBase-input": {
              border: 0,
              marginLeft: '-15px'

            },
           
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 70,
                    padding: 0,
                    border: 0,
                  }}
                >
                  <Select
                    disableUnderline={true}
                    sx={sxOptions}
                    size="small"
                    id="simple-select"
                    placeholder="Selecione"
                    disabled={type === "ISS" || disabled}
                    value={calcType}
                    onChange={(e) => {
                      setCalcType(e.target.value);
                    }}
                    onBlur={handleBlur}
                    MenuProps={{
                      style: { maxHeight: "450px" },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Não selecionado</em>
                    </MenuItem>
                    {renderMenuOptions(calculusOptions)}
                  </Select>
                </FormControl>
              </InputAdornment>
            ),
          }}
          value={value}
          onValueChange={handleChange}
          onBlur={handleBlur}
          prefix={
            ["NUMERIC", "CORTESY", "SERVICE"].includes(calcType)
              ? "R$ "
              : undefined
          }
          suffix={calcType === "PERCENTUAL" ? " %" : undefined}
          allowEmptyFormatting
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          placeholder="Digite um valor"
          customInput={TextField}
          color={"info"}
          size="small"
          style={{
            border: 0,
          }}
          disabled={disabled || calcType === ""}
          helperText={helperText}
        />
      ) : (
        <FormControl fullWidth>
          <Select
            fullWidth
            disableUnderline={true}
            size="small"
            id="simple-select"
            placeholder="Selecione"
            onBlur={handleBlur}
            value={calcType}
            disabled={disabled}
            onChange={(e) => setCalcType(e.target.value)}
          >
            <MenuItem value="">
              <em>Não selecionado</em>
            </MenuItem>
            {renderMenuOptions(calculusOptions)}
          </Select>
          {helperText && (
            <FormHelperText>
              A - Cobrar automaticamente apenas FEE
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default ChangelingMonetaryInput;
