import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { LabeledInput } from "../UI/LabeledInput";
import React, { useContext, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import SupplierService from "../../services/supplier.service";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import YesOrNoSwitch from "../UI/YesOrNoSwitch";
import { maskCPFOrCNPJ } from "../../utils/formatters";

type Props = {
  edit: any;
};
export function Supplier({ edit }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const supplierId = location.search.split("=");

  const { data: clientData, isLoading: clientLoading } = useQuery({
    queryKey: ["supplier"],
    queryFn: () => SupplierService.getProviders({ id: supplierId[1] }),
  });

  const [state, setState] = useState({} as any);
  const [taxId, setTaxId] = useState("");

  useEffect(() => {
    if (!!clientData) {
      setState(clientData[0]);

      const formData: any = clientData[0];

      if (formData?.cnpj_cpf) {
        const formattedValue = maskCPFOrCNPJ(formData?.cnpj_cpf);
        setTaxId(formattedValue);
      }
    }
  }, [clientData]);

  const { mutate: fetchCnpjData, isLoading: isLoadingFetchCnpj } = useMutation(
    SupplierService.fetchCnpjData,
    {
      onSuccess: (apiData) => {
        if (apiData) {
          setState((prevState: any) => ({
            ...prevState,
            zip_code: apiData.CEP || "",
            country: "Brasil",
            state: apiData.UF || "",
            city: apiData.MUNICIPIO || "",
            neighborhood: apiData.BAIRRO || "",
            street_type: apiData["LOGRADOURO"] || "",
            email: apiData.EMAIL || "",
            number: apiData.NUMERO || "",
            complement: apiData.COMPLEMENTO || "",
          }));
        }
      },
      onError: (error) => {
        console.error("Error fetching CNPJ data", error);
      },
    }
  );

  const { mutate: createSupplier, isLoading: isLoadingCreate } = useMutation(
    SupplierService.create,
    {
      onSuccess: () => {
        toast.success("Fornecedor criado com sucesso!");
        history.push("/partners/suppliers");
      },
      onError: (error) => {
        toast.error("Não foi possível criar o fornecedor");
      },
    }
  );

  const { mutate: updateSupplier, isLoading: isLoadingUpdate } = useMutation(
    (data: any) => SupplierService.update(state._id, data),
    {
      onSuccess: () => {
        toast.success("Fornecedor atualizado com sucesso!");
        history.push("/partners/suppliers");
      },
      onError: (error) => {
        toast.error("Não foi possível atualizar o fornecedor");
      },
    }
  );
  const handleSubmmit = (e: React.MouseEvent) => {
    const data = {
      ...state,
    };
    delete data._id;
    delete data.updated_at;
    !!state._id ? updateSupplier(data) : createSupplier(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "number") {
      const numberRegex = new RegExp("^[0-9]*$");
      if (!numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
    }

    if (e.target.name === "cnpj_cpf") {
      const isCPF = e.target.value.length <= 14;

      const taxState = {
        physical_person: isCPF,
        juridical_person: !isCPF,
      };

      const formattedValue = maskCPFOrCNPJ(e.target.value);
      setTaxId(formattedValue);

      setState({
        ...state,
        ...taxState,
        [e.target.name]: e.target.value,
      });

      return;
    }

    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTaxIdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const unmaskedCnpj = value.replace(/\D/g, "");
    fetchCnpjData(unmaskedCnpj);
  };
  const isDisabled =
    !actionOptions.updateParceiros || !actionOptions.createParceiros;

  const getTitle = () => {
    if (state._id) return `${state?.handle_id} - ${state?.name}`;
    else return "Criar fornecedor";
  };

  return (
    <Box
      display={"flex"}
      width={"100%"}
      columnGap={2}
      py={1}
      flexDirection={"column"}
    >
      <span style={{ fontSize: 20, marginBottom: 8 }}>{getTitle()}</span>
      <Box
        style={{
          padding: "20px 20px",
          boxShadow: "0px 0px 2px 1px #C4C4C4",
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box marginBottom={2}>
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            Informações básicas
          </span>
        </Box>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Box display={"flex"} gap={4}>
            <FormControl>
              <FormLabel>Fornecedor</FormLabel>

              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null)
                    setState({
                      ...state,
                      has_supplier: value,
                    });
                }}
                value={!!state.has_supplier}
                type=""
                align="flex-start"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Colaborador MM</FormLabel>

              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null)
                    setState({
                      ...state,
                      collaborator_mm: value,
                    });
                }}
                value={!!state.collaborator_mm}
                type=""
                align="flex-start"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Internacional</FormLabel>

              <YesOrNoSwitch
                setValue={(_, value) => {
                  if (value !== null)
                    setState({
                      ...state,
                      international: value,
                      national: !value,
                    });
                }}
                value={!!state.international}
                type=""
                align="flex-start"
              />
            </FormControl>
          </Box>

          <LabeledInput
            size="small"
            label={"CPF/CNPJ"}
            name="cnpj_cpf"
            value={taxId}
            onChange={handleChange}
            onBlur={handleTaxIdBlur}
            placeholder={
              state.physical_person ? "000.000.000-00" : "00.000.000/0000-00"
            }
          />
          <LabeledInput
            size="small"
            label="Solicitante do Cliente"
            name="client_requester"
            value={state.client_requester}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            label="Nome"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            label="Nome de uso/apelido"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
          <LabeledInput
            size="small"
            label="Nome Grupo"
            name="business_group_name"
            value={state.business_group_name}
            onChange={handleChange}
          />

          <Box
            style={{ height: 1, width: "100%", backgroundColor: "#E0E0E0" }}
            marginBottom={3}
            marginTop={3}
          />

          <Box marginBottom={2}>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>Contato</span>
          </Box>

          <LabeledInput
            size="small"
            fullWidth
            label="E-mail"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
          <ReactInputMask
            //@ts-ignore
            maskPlaceholder={null}
            mask="(99) 99999-9999"
            value={state.phone}
            onChange={handleChange}
            name="phone"
          >
            <LabeledInput
              size="small"
              label="Telefone"
              name="phone"
              value={state.phone}
              onChange={handleChange}
              placeholder="(00) 0 0000 0000"
            />
          </ReactInputMask>

          <Box
            style={{ height: 1, width: "100%", backgroundColor: "#E0E0E0" }}
            marginBottom={3}
            marginTop={3}
          />

          <Box marginBottom={2}>
            <span style={{ fontWeight: "bold", fontSize: 16 }}>Endereço</span>
          </Box>

          {/* <FormLabel
            style={{
              borderBottom: "1px solid",
              marginTop: "15px",
              width: "105%",
            }}
          >
            Endereço
          </FormLabel> */}

          <ReactInputMask
            //@ts-ignore
            maskPlaceholder={null}
            mask="99999-999"
            value={state.zip_code}
            onChange={handleChange}
            name="zip_code"
          >
            <LabeledInput
              size="small"
              label="CEP"
              name="zip_code"
              value={state.zip_code}
              onChange={handleChange}
              placeholder="00000-000"
            />
          </ReactInputMask>
          <LabeledInput
            size="small"
            fullWidth
            label="País"
            value={state.country}
            name="country"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            fullWidth
            label="Estado"
            value={state.state}
            name="state"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            fullWidth
            label="Município"
            value={state.city}
            name="city"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            fullWidth
            label="Bairro"
            value={state.neighborhood}
            name="neighborhood"
            onChange={handleChange}
          />

          <LabeledInput
            size="small"
            fullWidth
            label="Logradouro"
            value={state.street_type}
            name="street_type"
            onChange={handleChange}
          />
          <Box display={"flex"} width={"100%"} columnGap={1}>
            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Número"
              value={state.number}
              name="number"
              onChange={handleChange}
            />

            <LabeledInput
              size="small"
              style={{ width: "100%" }}
              fullWidth
              label="Complemento"
              value={state.complement}
              name="complement"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          width={"100%"}
          marginTop={4}
        >
          <Button
            style={{
              marginRight: "15px",
              textTransform: "none",
              fontSize: 16,
            }}
            variant="outlined"
            onClick={() => history.push("/partners/suppliers")}
          >
            Cancelar
          </Button>

          <Button
            style={{
              background: "#1361A4",
              color: "#FFFFFF",
              textTransform: "none",
              fontSize: 16,
            }}
            disabled={isDisabled}
            variant="contained"
            onClick={handleSubmmit}
          >
            Salvar e Fechar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
