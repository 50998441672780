import { FormControl, MenuItem, Select } from "@mui/material";
import { RxCaretRight } from "react-icons/rx";
import { useProject } from "../../../../../contexts/project.context";

type Props = {
  triggerAction(action: any): void;
  actionOptions: any;
  label: string;
};
const ActionSelect: React.FC<Props> = ({
  triggerAction,
  actionOptions,
  label,
}: Props) => {
  const { tabActive } = useProject();
  const sxOptions = {
    padding: 0,
    height: "80%",
    boxShadow: "none",
    border: " 1px solid #1361A4",
    color: "#1361A4",
    background: "#FFF",
    width: "100%",
  };
  function renderMenuOptions() {
    return actionOptions.map((el: any, index: any) => {
      if(tabActive === 9 && ["DUPLICATE", "NEGOTIATE"].includes(el.value)) return []
      if(tabActive === 8 && ["NEW_VERSION"].includes(el.value)) return []
      return (
        <MenuItem key={index} value={el.value}>
          {el.label}
        </MenuItem>
      );
    });
  }
  return (
    <FormControl size="small">
      <Select
        IconComponent={RxCaretRight}
        disableUnderline={true}
        sx={sxOptions}
        size="small"
        value={""}
        displayEmpty
        id="simple-select"
        placeholder="Selecione"
        onChange={(e) => {
          triggerAction(e.target.value);
        }}
      >
        <MenuItem disabled value="">
          <em
            style={{ fontSize: "13px", color: "#1361A4", fontStyle: "normal" }}
          >
            {label}
          </em>
        </MenuItem>
        {renderMenuOptions()}
      </Select>
    </FormControl>
  );
};

export default ActionSelect;
