import { Box } from "@mui/material"
import { LabeledInput } from "../../../UI/LabeledInput"

type Props = {
    fullCoinService?: number
    totalServiceConverted?: number
    exchangeCode?: string
}

const ServiceTotals: React.FC<Props> = ({ fullCoinService, totalServiceConverted, exchangeCode }: Props) => {
    return <Box display={'flex'} width={'100%'} gap={3}>
        <LabeledInput disabled label="Custo Total " value={`R$ ${fullCoinService}`} />
        <LabeledInput disabled label="Custo Total Convertido" value={!!exchangeCode ? exchangeCode + ` ${totalServiceConverted}` : 0} />

    </Box>
}

export default ServiceTotals