import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Button,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { LabeledInput } from "../../../UI/LabeledInput";
import {
  BudgetItemTypeEnum,
  IExchangeAndDescriptionByType,
} from "../../../../types/budget/budget";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import { Descriptions } from "../../../../types/budget/service-item";
import { toast } from "react-toastify";

type Props = {
  savedComposition: Descriptions
  compareComposition: Descriptions
  onSubmitComposition: (newComposition: Descriptions) => void
};

const Composition: React.FC<Props> = ({ savedComposition, compareComposition, onSubmitComposition }: Props) => {
  const [compositionState, setCompositionState] = useState({
    cancelPolicy: "",
    composition: "",
    paymentMethod: "",
  });

  function handleChange(field: string, value: any) {
    setCompositionState({ ...compositionState, [field]: value });
  }
  const { activeBudgetDispatch, activeCalcOption } = useBudget();

  useMemo(() => {
    if (!!savedComposition)
      setCompositionState({ ...savedComposition });
  }, [savedComposition]);


  function submitComposition() {
    onSubmitComposition(compositionState)
    toast.success('Descritivos salvos com sucesso!')

  }
  return (
    <Accordion
      sx={{ my: 1.5, borderRadius: "4px" }}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<BiChevronDown />}
        id="panel1a-header"
        sx={{
          minHeight: 40,
          maxHeight: 40,
          borderRadius: "4px",
          "&.Mui-expanded": {
            minHeight: 40,
            maxHeight: 40,
          },
        }}
      >
        <span style={{ fontWeight: "bold" }}>Descritivo</span>
      </AccordionSummary>
      <Box>{/* total summary */}</Box>

      <AccordionDetails>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <LabeledInput
            label="Composição"
            placeholder="Especifique"
            value={compositionState.composition}
            onChange={(e) => handleChange("composition", e.target.value)}
          />
          <LabeledInput
            label="Política de cancelamento"
            placeholder="Especifique"
            value={compositionState.cancelPolicy}
            onChange={(e) => handleChange("cancelPolicy", e.target.value)}
          />
          <LabeledInput
            label="Forma de pagamento"
            placeholder="Especifique"
            value={compositionState.paymentMethod}
            onChange={(e) => handleChange("paymentMethod", e.target.value)}
          />
        </Box>
        <Box display={"flex"}>
          <Button
            onClick={submitComposition}
            sx={{ ml: "auto" }}
            variant="outlined"
          >
            Salvar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Composition;
