import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Button,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { LabeledInput } from "../../../UI/LabeledInput";
import CoinData from "../../../../utils/Common-Currency.json";
const coinOptions = CoinData.map((el) => {
  return { value: el.code, label: el.name };
});
import {
  BudgetItemTypeEnum,
  initialPaymentSolicitation,
  IPaymentSolicitationByType,
} from "../../../../types/budget/budget";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import ChangelingMonetaryInput from "../../../UI/ChangelingMonetaryInput";

type Props = {
  type: keyof typeof BudgetItemTypeEnum;
  supplierId: string;
  savedPaymentInfo: IPaymentSolicitationByType;
};

const PaymentMethodInfo: React.FC<Props> = ({
  type,
  supplierId,
  savedPaymentInfo,
}: Props) => {
  const [paymentInfo, setPaymentInfo] = useState<IPaymentSolicitationByType>(
    initialPaymentSolicitation
  );

  function handleChange(value: any, field: string) {
    setPaymentInfo({ ...paymentInfo, [field]: value });
  }
  const { activeBudgetDispatch, activeCalcOption } = useBudget();

  useMemo(() => {
    if (!!savedPaymentInfo) setPaymentInfo({ ...savedPaymentInfo });
  }, [savedPaymentInfo]);

  function updateCalcValues(type: string, value: number, calcType: string) {
    handleChange(
      {
        currency: calcType,
        value,
      },
      type
    );
  }

  function submitComposition() {
    activeBudgetDispatch({
      type:
        savedPaymentInfo._id === "new"
          ? "CREATE_PAYMENT_INFO"
          : "UPDATE_PAYMENT_INFO",
      payload: {
        paymento: paymentInfo,
        supplierId,
        type,
        calcOption: activeCalcOption,
      },
    });
  }
  return (
    <Accordion
      sx={{ my: 1.5, borderRadius: "4px" }}
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<BiChevronDown />}
        id="panel1a-header"
        sx={{
          minHeight: 40,
          maxHeight: 40,
          borderRadius: "4px",
          "&.Mui-expanded": {
            minHeight: 40,
            maxHeight: 40,
          },
        }}
      >
        <span style={{ fontWeight: "bold" }}>Solicitação de pagamento</span>
      </AccordionSummary>
      <Box>{/* total summary */}</Box>

      <AccordionDetails>
        <Box display={"flex"} gap={3}>
          <LabeledInput
            value={paymentInfo.exchangeData.date}
            onChange={(e) => handleChange(e.target.value, "exchangeData-date")}
            type="date"
            label="Data cotação"
          />
          <ChangelingMonetaryInput
            calculusOptions={coinOptions}
            prevValue={{
              calcType: paymentInfo.exchangeData.currency,
              value: paymentInfo.exchangeData.value,
            }}
            disabled={false}
            label={"Moeda / valor dia"}
            type={"exchangeData"}
            updateCalcValues={updateCalcValues}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <LabeledInput
            label="Tipo de comissionamento"
            placeholder="Especifique"
            value={paymentInfo.comisionType}
            onChange={(e) => handleChange("comission", e.target.value)}
          />
          <LabeledInput
            label="Cobrança / Forma que cliente paga"
            placeholder="Especifique"
            value={paymentInfo.billing}
            onChange={(e) => handleChange("billing", e.target.value)}
          />
          {/* // change to select */}
          <LabeledInput
            label="Forma de pagamento"
            placeholder="Especifique"
            value={paymentInfo.paymentMethod}
            onChange={(e) => handleChange("paymentMethod", e.target.value)}
          />
          <LabeledInput
            label="Descritivo ou valores da forma de pagamento"
            placeholder="Especifique"
            value={paymentInfo.paymentMethodDescription}
            onChange={(e) =>
              handleChange("paymentMethodDescription", e.target.value)
            }
          />
          <LabeledInput
            label="Cobrança / Forma que cliente paga"
            placeholder="Especifique"
            value={paymentInfo.billing}
            onChange={(e) => handleChange("billing", e.target.value)}
          />
          <Box display={"flex"} gap={3}>
            <LabeledInput
              value={paymentInfo.downPaymentExchangeData.date}
              onChange={(e) => handleChange(e.target.value, "downPaymentExchangeData-date")}
              type="date"
              label="Data cotação"
            />
            <ChangelingMonetaryInput
              calculusOptions={coinOptions}
              prevValue={{
                calcType: paymentInfo.downPaymentExchangeData.currency,
                value: paymentInfo.downPaymentExchangeData.value,
              }}
              disabled={false}
              label={"Moeda / valor dia"}
              type={"downPaymentExchangeData"}
              updateCalcValues={updateCalcValues}
            />
          </Box>
        </Box>

        <Box display={"flex"}>
          <Button
            onClick={submitComposition}
            sx={{ ml: "auto" }}
            variant="outlined"
          >
            Salvar
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentMethodInfo;
