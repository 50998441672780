import isBefore from "date-fns/isBefore";
import _ from "lodash";
import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import ProjectService from "../services/project.service";
import ProjectSummaryService from "../services/projectSummary.service";
import {
  Project,
  ProjectAssignTime,
  ProjectClient,
  ProjectEvent,
  ProjectFinance,
  ProjectRequester,
  ProjectStatus,
  ProjectSummary,
  ProjectSummaryContext,
} from "../types";
import { BudgetStatus } from "../types/budget/budget";
import { TRANSITION_REQUIREMENTS } from "../utils/briefingStatus";

export type ProjectContextProps = {
  notification?: any;
  loadingProject?: boolean;

  project?: Project;
  client?: ProjectClient;
  requester?: ProjectRequester;
  assignTime?: ProjectAssignTime;
  event?: ProjectEvent;
  finance?: ProjectFinance;
  summary?: any;
  unsavedSummary?: any;

  loadProject: (projectId: string) => void;
  closeNotification: () => void;
  showNotification: (message: string, severity: string) => void;

  updateStatus: (status: ProjectStatus, isReturn?: boolean) => void;
  updateClient: (client: ProjectClient) => void;
  updateAssignTime: (assignTime: ProjectAssignTime) => void;
  updateEvent: (event: ProjectEvent) => Promise<void>;
  updateFinance: (finance: ProjectFinance) => void;
  updateRequester: (requester: ProjectRequester) => void;
  updateSummaryContext: (
    type: "ON_APPROVAL" | "APPROVED" | "CLOSURE" | "AUDITED",
    summary: ProjectSummary
  ) => void;
  clearProject: () => void;
  tabActive: number;
  setTabActive: (step: number) => void;
  handleSetUnsavedSummary: (value: any) => void;
};

const ProjectContext = createContext<ProjectContextProps>(
  {} as ProjectContextProps
);

function ProjectProviderNew({ children }: any) {
  const [loadingProject, setLoadingProject] = useState(false);

  const [notification, setNotification] = useState<any>({
    open: false,
    message: "",
    severity: "success",
  });

  const [project, setProject] = useState<any>();
  const [client, setClient] = useState<ProjectClient>();
  const [requester, setRequester] = useState<ProjectRequester>();
  const [assignTime, setAssignTime] = useState<ProjectAssignTime>();
  const [event, setEvent] = useState<ProjectEvent>();
  const [finance, setFinance] = useState<ProjectFinance>();
  const [summary, setSummary] = useState<any>();
  const [tabActive, setTabActive] = useState(0);
  const [unsavedSummary, setUnsavedSummary] = useState({
    active: false,
    saveFn: null,
  });

  function getActionLabel() {
    return project?._id ? "atualizado" : "adicionado";
  }

  async function updateClient(client: ProjectClient) {
    if (project?._id) {
      await ProjectService.update(project._id, {
        client: client.clientSelected._id,
        requestings: client.requestings.map((requesting) => requesting._id),
      });

      await reloadLastUpdated(project._id);
    }

    setClient(client);
    showNotification(`Cliente ${getActionLabel()} com sucesso`);
  }
  async function updateRequester(requester: ProjectRequester) {
    if (project?._id)
      await ProjectService.update(project._id, {
        requester: requester._id,
      });

    setRequester(requester);
  }

  async function updateAssignTime(assignTime: ProjectAssignTime) {
    if (project?._id) {
      await ProjectService.update(project._id, {
        commercial: assignTime.commercial._id,
        firstAttendance: assignTime.firstAttendance._id,
        secondAttendance: assignTime.secondAttendance?._id,

        groups: assignTime.groups
          .filter((projectGroup) => projectGroup.group._id)
          .map((projectGroup) => ({
            group: projectGroup.group?._id,
            leader: projectGroup.leader?._id,
            firstCoordinator: projectGroup.firstCoordinator?._id,
            secondCoordinator: projectGroup.secondCoordinator?._id,
          })),
      });

      await reloadLastUpdated(project._id);
    }

    setAssignTime(assignTime);
    showNotification(`Time ${getActionLabel()} com sucesso`);
  }

  async function updateStatus(status: ProjectStatus, isReturn?: boolean) {
    if (!isReturn) {
      const errors = validateCanHaveStatus(status.status);
      if (errors?.error) {
        for (const message of errors.messages) {
          toast.error(message);
        }
        return;
      }
    }
    if (project?._id) await ProjectService.updateStatus(status);

    setProject({ ...project, status });
    showNotification(`Status atualizado com sucesso`);
  }

  async function updateEvent(event: ProjectEvent) {
    if (project?._id) {
      await ProjectService.update(project._id, { event });
      await reloadLastUpdated(project._id);
    } else {
      await createProject(event);
    }

    setEvent(event);
    showNotification(`Evento ${getActionLabel()} com sucesso`);
  }

  function updateSummaryContext(
    type: "ON_APPROVAL" | "APPROVED" | "CLOSURE" | "AUDITED",
    newSummary: any
  ) {
    setSummary({ ...summary, [type]: newSummary });
  }

  async function updateFinance(finance: ProjectFinance) {
    if (project?._id) {
      await ProjectService.update(project._id, { finance });
      await reloadLastUpdated(project._id);
    } else {
    }

    setFinance(finance);
    showNotification(`Previsão de custo ${getActionLabel()} com sucesso`);
  }

  function showNotification(message: string, severity = "success") {
    setNotification({ open: true, message, severity });
  }

  function closeNotification() {
    setNotification({ ...notification, open: false });
  }

  function validateFinancialFields() {
    if (finance) {
      const errorObj = {
        error: false,
        messages: [] as string[],
      };
      for (const [key, value] of Object.entries(finance)) {
        switch (key) {
          case "billingDeadline":
            if (value.trim() === "") {
              errorObj.error = true;
              errorObj.messages.push(
                'No status "Em aprovação" o campo Prazo de faturamento é obrigatório'
              );
            }
            break;
          case "paymentMethod":
            if (value.trim() === "") {
              errorObj.error = true;
              errorObj.messages.push(
                'No status "Em aprovação" o campo Forma de pagamento é obrigatório'
              );
            }
            break;
          case "debitNote":
            if (value.trim() === "") {
              errorObj.error = true;
              errorObj.messages.push(
                'No status "Em aprovação" o campo Nota de débito é obrigatório'
              );
            }
            break;
        }
      }
      return errorObj;
    }
    return {
      error: true,
      messages: [
        'Nâo é possível atualizar o status para "Em aprovação" sem que os valores de previsão de custo estejam preenchidos',
      ],
    };
  }
  interface Obj {
    [key: string]: any;
  }
  const requiredFields: Obj = {
    local: "Local do Evento",
    zip_code: "CEP",
    city: "Cidade",
    state: "Estado",
    country: "País",
    complement: "Logradouro",
    number: "Número",
  };

  const fieldTranslations: Obj = {
    totalValueOfEvents: "Valor total do evento",
    directPaymentOfClients: "Cliente possui adiantamento ?",
    logisticFee: "Fee de logística",
    logisticMarkup: "Markup de logística",
    logisticComission: "Comissão de logística",
    airFee: "Fee de aéreo",
    airMarkup: "Markup de aéreo",
    airComission: "Comissão de aéreo",
    RSVP: "RSVP",
    communicationFee: "Fee de comunicação",
    taxes: "Impostos",
    customerBudget: "Budget do cliente",
    acceptAdvances: "Aceita adiantamento ?",
    billingMethod: "Forma de faturamento",
    taxPercentage: "Percentual de imposto",
  };

  function getErrorMessage(key: string) {
    return `O campo ${requiredFields[key]} é obrigatório`;
  }

  function fieldErrorMessage(field: string) {
    return `O campo "${fieldTranslations[field]}" é obrigatório`;
  }

  function validateAddressFields() {
    if (event) {
      const errorObj = {
        error: false,
        messages: [] as string[],
      };
      for (const [key, value] of Object.entries(event)) {
        if (!value && !!requiredFields[key]) {
          errorObj.error = true;
          errorObj.messages.push(getErrorMessage(key));
        }
      }
      return errorObj;
    }
    return {
      error: true,
      messages: [
        "Nâo é possível atualizar o status sem que os valores de previsão de custo estejam preenchidos",
      ],
    };
  }

  function handleSetUnsavedSummary(value: any) {
    setUnsavedSummary(value);
  }

  function validateSummaryFields(status: string) {
    if (event) {
      const errorObj = {
        error: false,
        messages: [] as string[],
      };
      const summaryRequiredFields = [
        "totalValueOfEvents",
        "directPaymentOfClients",
        "logisticFee",
        "logisticMarkup",
        "logisticComission",
        "airFee",
        "airMarkup",
        "airComission",
        "RSVP",
        "communicationFee",
      ];

      const eventRequiredFields = [
        "customerBudget",
        "acceptAdvances",
        "billingMethod",
      ];

      for (const i in summaryRequiredFields) {
        const currentField = summary[status]
          ? summary[status][summaryRequiredFields[i]]
          : undefined;
        if (currentField === undefined || currentField === null) {
          errorObj.error = true;
          errorObj.messages.push(fieldErrorMessage(summaryRequiredFields[i]));
        }
      }

      const eventCtx = event as any;

      for (const i in eventRequiredFields) {
        const currentField = eventCtx[eventRequiredFields[i]];
        if (
          currentField === undefined ||
          currentField === null ||
          currentField === ""
        ) {
          errorObj.error = true;
          errorObj.messages.push(fieldErrorMessage(eventRequiredFields[i]));
        }
      }

      return errorObj;
    }
    return {
      error: true,
      messages: [
        "Nâo é possível atualizar o status sem que os valores de dados do evento estejam preenchidos",
      ],
    };
  }

  function validateCanHaveStatus(status: string) {
    const createdProjectDate = project?.createdAt;

    if (!createdProjectDate) return null;

    // const summaryValidationDate = new Date(2024, 6, 31, 23, 59, 0);

    // const shouldValidateSummary = !isBefore(
    //   new Date(createdProjectDate),
    //   summaryValidationDate
    // );

    // if (!shouldValidateSummary) return null;

    const requirements = TRANSITION_REQUIREMENTS[status];

    let addressFields = null;
    let summaryFields = null;

    if (requirements) {
      if (requirements.address) {
        addressFields = validateAddressFields();
      }

      if (requirements.summary) {
        summaryFields = validateSummaryFields(requirements.summary);
      }

      if (addressFields?.error || summaryFields?.error) {
        const mergedMessages = [
          ...(addressFields?.messages || []),
          ...(summaryFields?.messages || []),
        ];
        return {
          error: true,
          messages: mergedMessages,
        };
      }
      return null;
    }
    return { error: true, messages: [] };
  }

  async function reloadLastUpdated(projectId: string) {
    const projectData = await ProjectService.get(projectId).then();
    setProject({
      ...project,
      updatedAt: projectData.updatedAt,
      lastUserUpdated: projectData?.lastUserUpdated,
    });
  }

  async function loadProject(projectId: string) {
    setLoadingProject(true);
    const projectData = await ProjectService.get(projectId).then();

    const onApprovalSummary = await ProjectSummaryService.get({
      project: projectId,
      status: BudgetStatus.ON_APPROVAL,
    });
    const approvedSummary = await ProjectSummaryService.get({
      project: projectId,
      status: BudgetStatus.APPROVED,
    });
    const closureSummary = await ProjectSummaryService.get({
      project: projectId,
      status: BudgetStatus.CLOSURE,
    });
    const auditedSumamry = await ProjectSummaryService.get({
      project: projectId,
      status: BudgetStatus.AUDITED,
    });

    setProject(projectData);

    const {
      requestings,
      event,
      client,
      commercial,
      firstAttendance,
      secondAttendance,
      groups,
      ...finance
    } = projectData;

    setClient({ clientSelected: client, requestings });

    setEvent({
      ...event,
      startDate: new Date(event.startDate),
      endDate: new Date(event.endDate),
      sendDate: new Date(event.sendDate),
      briefingStartDate: new Date(event.briefingStartDate),
      presentationDate:
        event.presentationDate && new Date(event.presentationDate),
    });
    setAssignTime({
      commercial: commercial ?? {},
      firstAttendance: firstAttendance ?? {},
      secondAttendance: secondAttendance ?? {},
      groups,
    });
    setFinance(finance);
    setSummary({
      ON_APPROVAL: onApprovalSummary?.[0],
      APPROVED: approvedSummary?.[0],
      CLOSURE: closureSummary?.[0],
      AUDITED: auditedSumamry?.[0],
    });

    setLoadingProject(false);
  }

  async function createProject(event: ProjectEvent) {
    if (!client) return showNotification("Selecione um cliente", "error");
    if (!assignTime) return showNotification("Selecione um time", "error");
    if (!event) return showNotification("Preencha o evento", "error");

    const data = {
      client: client.clientSelected._id,
      requestings: client.requestings.map((requesting) => requesting._id),
      commercial: assignTime.commercial._id,
      firstAttendance: assignTime.firstAttendance._id,
      secondAttendance: assignTime.secondAttendance?._id,

      groups: assignTime.groups
        .filter((projectGroup) => projectGroup.group._id)
        .map((projectGroup) => ({
          group: projectGroup.group?._id,
          leader: projectGroup.leader?._id,
          firstCoordinator: projectGroup.firstCoordinator?._id,
          secondCoordinator: projectGroup.secondCoordinator?._id,
        })),

      event,
      requester,
    };

    const project = await ProjectService.create(data);

    await Promise.all([
      ProjectSummaryService.create({
        project: project?._id,
        status: BudgetStatus.ON_APPROVAL,
      }),
      ProjectSummaryService.create({
        project: project?._id,
        status: BudgetStatus.APPROVED,
      }),
      ProjectSummaryService.create({
        project: project?._id,
        status: BudgetStatus.CLOSURE,
      }),
      ProjectSummaryService.create({
        project: project?._id,
        status: BudgetStatus.AUDITED,
      }),
    ]);

    setProject(project);
  }

  function clearProject() {
    setProject(undefined);
    setClient(undefined);
    setAssignTime(undefined);
    setEvent(undefined);
    setFinance(undefined);
    setTabActive(0);
  }

  return (
    <ProjectContext.Provider
      value={{
        tabActive,
        notification,
        loadingProject,

        client,
        project,
        assignTime,
        event,
        finance,
        summary,
        unsavedSummary,

        setTabActive,
        loadProject,
        showNotification,
        closeNotification,
        updateStatus,
        updateClient,
        updateAssignTime,
        updateEvent,
        updateFinance,
        updateRequester,
        updateSummaryContext,
        clearProject,
        handleSetUnsavedSummary,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

const useProject = () => useContext(ProjectContext);
export { ProjectProviderNew, useProject };
