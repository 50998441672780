import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MdSearch } from "react-icons/md";
import { Type } from "typescript";
import { Column } from "react-table";
import TableComponent from "../../../CustomDataGrid";
import { FaEye, FaSyncAlt } from "react-icons/fa";
import { TbEyeEdit, TbTrash } from "react-icons/tb";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import ProviderService from "../../../../services/supplier.service";
import { GridSortDirection } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contexts/auth";
import { keyframes } from "@mui/system";

type Props = {
  onSelect(type: string, item: any): void;
};

const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  order: "asc" as GridSortDirection,
  sortField: "name",
  search: "",
  origin: "",
};
export function Suppliers({ onSelect }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const [syncing, setSyncing] = useState(false);
  const [providerData, setProviderData] = useState<any>(INITIAL_STATE);
  const updatePage = (pageNumber: number) => {
    setProviderData({ ...providerData, page: pageNumber });
  };
  const updateRowsPerPage = (limit: number) => {
    setProviderData({ ...providerData, pageSize: limit, page: 0 });
  };

  const handleEdit = (id: string): void => {
    let item = data?.providers.find((el) => el._id === id);
    if (!!item) onSelect("supplier", item);
  };
  const handleDelete = async (id: string): Promise<void> => {
    try {
      // await ProviderService.delete(id)
    } catch (error) {
      toast.error("Não foi possível apagar o Cliente");
    }
  };
  const { isLoading, data, error } = useQuery({
    queryKey: ["providers_search", providerData],
    queryFn: () => ProviderService.search(providerData),
  });

  const handleSync = async () => {
    setSyncing(true);
    try {
      await ProviderService.sync();
      toast.success("Benner sincronizado com sucesso");
    } catch (error) {
      toast.error("Erro ao tentar sincronizar com o Benner");
    } finally {
      setSyncing(false);
    }
  };
  interface IClientRow extends Type {
    _id: string;
    handle_id: string;
    name: string;
    nickname: string;
    business_group_name: string;
    cnpj_cpf: string;
    projects: any[];
  }

  const columns: Column<IClientRow>[] = [
    {
      Header: "ID",
      accessor: "handle_id",
      Filter: "",
      width: 60,
    },
    {
      Header: " FORNECEDOR",
      accessor: "name",
      Filter: "",
      width: 300,
      Cell: ({ cell }) => {
        return (
          <div>
            <span>
              <strong>{cell.row.original.nickname}</strong> -{" "}
              {cell.row.original.business_group_name}
            </span>
            <br />
            <span>{cell.row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: (
        <span>
          CNPJ OU CPF &nbsp; <FaEye /> &nbsp;{" "}
        </span>
      ),
      accessor: "cnpj_cpf",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <span>{cell.row.values.cnpj_cpf}</span>
          </div>
        );
      },
    },
    {
      Header: "",
      width: 60,
      accessor: "_id",
      Cell: ({ cell }) => (
        <div
          onClick={() => {}}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {" "}
          <Button
            variant="outlined"
            style={{ maxWidth: "20px" }}
            onClick={() => {
              handleEdit(cell.value);
            }}
          >
            <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
          </Button>
          {/* <Button variant="outlined" style={{maxWidth: '20px'}} disabled={!actionOptions['deleteParceiros']} onClick={() => { handleDelete(cell.value) }}><TbTrash size={18} style={{ cursor: "pointer" }} /></Button> */}
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <>
      <span style={{ marginLeft: 16, fontSize: 18 }}>Filtros</span>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"98%"}
        alignItems={"flex-end"}
        margin="5px auto"
        gap={2}
      >
        <Box flex={1}>
          <span style={{ fontSize: 14 }}>Origem do cadastro</span>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={providerData.origin}
              size="small"
              displayEmpty
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <span style={{ color: "#A2A2A2" }}>Selecionar</span>;
                }

                return selected;
              }}
              onChange={(e: any) => {
                setProviderData({ ...providerData, origin: e.target.value });
              }}
              MenuProps={{
                style: {
                  maxHeight: 450,
                },
              }}
            >
              <MenuItem value="BENNER"> Benner</MenuItem>
              <MenuItem value="MMIDD"> MMIDD</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          size="small"
          placeholder="Buscar cnpj, cpf ou fornecedor"
          value={providerData.search}
          onChange={(e) => {
            setProviderData({
              ...providerData,
              search: e.target.value,
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%", flex: 4 }}
        />

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            cursor: "pointer",
            border: "1px solid #1361A4",
            borderRadius: 4,
            minHeight: 36,
          }}
          onClick={() => (syncing ? null : handleSync())}
          flex={1}
        >
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            padding={1}
            alignItems={"center"}
            sx={{ animation: syncing ? `${spin} 1.5s infinite ease` : `none` }}
          >
            <FaSyncAlt color="#1361A4" />
          </Box>
          <span style={{ color: "#1361A4" }}>Sincronizar com Benner</span>
        </Box>
      </Box>

      {providerData.search.length > 0 ||
        (providerData.origin.length > 0 && (
          <Box height={"60px"} width={"95%"} margin={"0 auto"}>
            {providerData.search.length > 0 && (
              <Chip
                label={providerData.search}
                variant="outlined"
                onDelete={() => setProviderData(INITIAL_STATE)}
              />
            )}
            {providerData.origin.length > 0 && (
              <Chip
                label={`Origem: ${providerData.origin}`}
                variant="outlined"
                onDelete={() =>
                  setProviderData({ ...providerData, origin: "" })
                }
              />
            )}
          </Box>
        ))}

      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"98%"}
        margin="5px auto"
      >
        <TableComponent
          columns={columns}
          data={data?.providers || []}
          isLoading={isLoading}
          givenPage={providerData.page}
          rowsPerPage={providerData.pageSize}
          total={data?.total || 0}
          onPageChange={updatePage}
          onRowsPerPageChange={updateRowsPerPage}
        />
      </Box>
    </>
  );
}
