import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import { FaSyncAlt } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { TbEyeEdit, TbTrash } from "react-icons/tb";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { Type } from "typescript";

import { AuthContext } from "../../../../contexts/auth";
import ClientService from "../../../../services/client.service";
import TableComponent from "../../../CustomDataGrid";
import { keyframes, minHeight } from "@mui/system";

type Props = {
  onSelect(type: string, item: any): void;
};
const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  order: "asc" as GridSortDirection,
  sortField: "name",
  search: "",
  origin: "",
};
export function Customers({ onSelect }: Props) {
  const { actionOptions } = useContext(AuthContext);
  const [syncing, setSyncing] = useState(false);
  const [clientData, setClientData] = useState<any>(INITIAL_STATE);
  const updatePage = (pageNumber: number) => {
    setClientData({ ...clientData, page: pageNumber });
  };
  const updateRowsPerPage = (limit: number) => {
    setClientData({ ...clientData, pageSize: limit, page: 0 });
  };
  const handleEdit = (id: string): void => {
    const item = data?.clients.find((el) => el._id === id);
    if (item) onSelect("client", item);
  };
  const handleDelete = async (id: string): Promise<void> => {
    try {
      await ClientService.delete(id);
    } catch (error) {
      toast.error("Não foi possível apagar o Cliente");
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["clients_search", clientData],
    queryFn: () => ClientService.search(clientData),
  });

  const handleSync = async () => {
    setSyncing(true);
    try {
      await ClientService.sync();
      toast.success("Benner sincronizado com sucesso");
    } catch (error) {
      toast.error("Erro ao tentar sincronizar com o Benner");
    } finally {
      setSyncing(false);
    }
  };
  interface IClientRow extends Type {
    _id: string;
    handle_id: string;
    name: string;
    nickname: string;
    business_group_name: string;
    tax_id: string;
    projs: any[];
  }

  const columns: Column<IClientRow>[] = [
    {
      Header: "ID",
      accessor: "handle_id",
      Filter: "",
      width: 60,
    },
    {
      Header: "Cliente",
      accessor: "name",
      Filter: "",
      width: 300,
      Cell: ({ cell }) => {
        return (
          <div>
            <span>
              <strong>{cell.row.original.nickname}</strong> -{" "}
              {cell.row.original.business_group_name}
            </span>
            <br />
            <span>{cell.row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: <span>CNPJ OU CPF </span>,
      accessor: "tax_id",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <span>{cell.row.values.tax_id}</span>
          </div>
        );
      },
    },
    {
      Header: <span>PROJETOS</span>,
      accessor: "projs",
      Filter: "",
      Cell: ({ cell }) => {
        return (
          <div>
            <span>{cell.value.length} ativos</span>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "_id",
      width: 60,
      Cell: ({ cell }) => (
        <div
          onClick={() => {}}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {" "}
          <Button
            variant="outlined"
            style={{ maxWidth: "20px" }}
            onClick={() => {
              handleEdit(cell.value);
            }}
          >
            <TbEyeEdit size={18} style={{ cursor: "pointer" }} />
          </Button>
          {/* <Button
            variant="outlined"
            style={{ maxWidth: "20px" }}
            disabled={!actionOptions.deleteParceiros}
            onClick={() => {
              handleDelete(cell.value);
            }}
          >
            <TbTrash size={18} style={{ cursor: "pointer" }} />
          </Button> */}
        </div>
      ),
      Filter: "",
      disableSortBy: true,
    },
  ];

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <>
      <span style={{ marginLeft: 16, fontSize: 18 }}>Filtros</span>
      <Box
        display={"flex"}
        width={"98%"}
        margin="5px auto"
        justifyContent={"space-between"}
        alignItems={"flex-end"}
        gap={2}
      >
        <Box flex={1}>
          <span style={{ fontSize: 14 }}>Origem do cadastro</span>

          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={clientData.origin}
              displayEmpty
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <span style={{ color: "#A2A2A2" }}>Selecionar</span>;
                }

                return selected;
              }}
              size="small"
              onChange={(e: any) => {
                setClientData({ ...clientData, origin: e.target.value });
              }}
              MenuProps={{
                style: {
                  maxHeight: 450,
                },
              }}
            >
              <MenuItem value="BENNER"> BENNER</MenuItem>
              <MenuItem value="MMIDD"> MMIDD</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          size="small"
          placeholder="Buscar cnpj, cpf, célula ou cliente"
          value={clientData.search}
          onChange={(e) => {
            setClientData({ ...clientData, search: e.target.value });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%", flex: 4 }}
        />

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            cursor: "pointer",
            border: "1px solid #1361A4",
            borderRadius: 4,
            minHeight: 36,
          }}
          onClick={() => (syncing ? null : handleSync())}
          flex={1}
        >
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            padding={1}
            alignItems={"center"}
            sx={{ animation: syncing ? `${spin} 1.5s infinite ease` : `none` }}
          >
            <FaSyncAlt color="#1361A4" />
          </Box>
          <span style={{ color: "#1361A4" }}>Sincronizar com Benner</span>
        </Box>
      </Box>
      {clientData.search.length > 0 ||
        (clientData.origin.length > 0 && (
          <Box height={"60px"} width={"95%"} margin={"0 auto"}>
            {clientData.search.length > 0 && (
              <Chip
                label={clientData.search}
                variant="outlined"
                onDelete={() => setClientData(INITIAL_STATE)}
              />
            )}
            {clientData.origin.length > 0 && (
              <Chip
                label={`Origem: ${clientData.origin}`}
                variant="outlined"
                onDelete={() => setClientData({ ...clientData, origin: "" })}
              />
            )}
          </Box>
        ))}
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"98%"}
        margin="5px auto"
      >
        <TableComponent
          columns={columns}
          data={data?.clients || []}
          isLoading={isLoading}
          givenPage={clientData.page}
          rowsPerPage={clientData.pageSize}
          total={data?.total || 0}
          onPageChange={updatePage}
          onRowsPerPageChange={updateRowsPerPage}
        />
      </Box>
    </>
  );
}
