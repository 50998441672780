import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import BudgetSummary from "./BudgetSummary";
import { useBudget } from "../../../../contexts/BudgetContext/projectbudgets.context";
import { RadioGroup } from "../../../UI/RadioGroup";
import { VscChevronDown } from "react-icons/vsc";
import { QueryClient, useMutation } from "react-query";
import { BudgetService } from "../../../../services/budget.service";
import { useLocation } from "react-router-dom";
import { useProject } from "../../../../contexts/project.context";
import { groupByAttributes } from "../../../ExportNegotiation";

enum ExportType {
  MM = "MM",
  CLIENT = "CLIENT",
}

enum ExportFormat {
  PDF = "PDF",
  XLX = "XLX",
}

const exportActiveLabel: { [index: number]: string } = {
  8: "orçamentos",
  9: "negociações",
  10: "fechamentos",
};

const RightSideBar: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const project = searchParams.get("projectId");
  const {
    budgetList,
    activeBudget,
    showNotification,
    createEmptyBudget,
    activeBudgetDispatch,
    setNegotiationVersion,
  } = useBudget();
  const { tabActive } = useProject();
  const [detailedBudget, setDetailedBudget] = useState<string | undefined>(
    activeBudget?._id
  );

  const [exportType, setExportType] = useState(ExportType.MM);
  const [exportFormat, setExportFormat] = useState(ExportFormat.PDF);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  function handleChangeDetailedBudget(budgetIdentifier: string) {
    let budgetId = budgetIdentifier;
    if (tabActive === 9) {
      budgetId = budgetIdentifier.split("-")[0];
    }
    const newActiveBudget = budgetList?.find((el) => el._id === budgetId);
    const newActiveBudgetIndex = budgetList?.findIndex(
      (el) => el._id === budgetId
    );
    if (newActiveBudgetIndex !== undefined) {
      activeBudgetDispatch({
        type: "NEW_ACTIVE_BUDGET",
        payload: newActiveBudget,
      });
    }
    if (tabActive === 9) {
      setNegotiationVersion(Number(budgetIdentifier.split("-")[2]));
    }
    setDetailedBudget(budgetIdentifier);
  }
  const queryClient = new QueryClient();
  const { mutate: exportBudget, isLoading: isLoadingExport } = useMutation(
    (data: any) => BudgetService.exportXLX(data),
    {
      onSuccess: (data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const link = document.createElement("a");
        const fileName = "PrevisaoDeCustos.xlsx";

        link.setAttribute("download", fileName);
        link.href = URL.createObjectURL(blob);

        document.body.appendChild(link);
        link.click();

        link.remove();

        showNotification("Exportado com sucesso!", "success");
      },
      onError: (error) => {
        showNotification("Não foi possível exportar", "warning");
      },
    }
  );

  function handleExportBudget(idsArr: any) {
    if (!!idsArr && idsArr.length < 1) return;
    if (exportFormat === ExportFormat.XLX) {
      exportBudget(idsArr?.[0]);
    } else {
      const exportTypeQuery = exportType === ExportType.MM ? "mm" : "client";
      if (idsArr.length === budgetList?.length) {
        window.open(
          `/export?type=${exportTypeQuery}&projectId=${project}`,
          "_blank"
        );
      } else {
        window.open(
          `/export?type=${exportTypeQuery}&projectId=${project}&budgetId=${idsArr?.[0]}`,
          "_blank"
        );
      }
    }
  }

  function renderBudgetSumaries() {
    if (!!budgetList && budgetList.length > 0) {
      switch (tabActive) {
        case 9:
          // nested loops to render each negotiation
          const summaries = [];
          for (let j = 0; j < budgetList.length; j++) {
            if (budgetList[j].status && budgetList[j].status === "NEGOTIATE") {
              for (let i = 0; i < budgetList[j].negotiationVersion; i++) {
                summaries.push(
                  <BudgetSummary
                    budget={budgetList[j]}
                    index={j}
                    version={i + 1}
                    status={activeBudget?.status}
                  />
                );
              }
            }
          }
          return summaries;

        case 10:
          return budgetList.map((el, index) => {
            if (el.status && el.status === "APROVED")
              return (
                <BudgetSummary
                  budget={el}
                  index={index}
                  status={activeBudget?.status}
                />
              );
          });
        case 8:
        default:
          return budgetList.map((el, index) => {
            if (
              el.status &&
              ["NEGOTIATE", "APROVED", "EXCLUDED"].includes(el.status)
            )
              return <></>;
            else
              return (
                <BudgetSummary
                  budget={el}
                  index={index}
                  status={activeBudget?.status}
                />
              );
          });
      }
    }

    return !!budgetList && budgetList.length > 0 ? (
      budgetList.map((el, index) => {
        return (
          <BudgetSummary
            budget={el}
            index={index}
            status={activeBudget?.status}
          />
        );
      })
    ) : (
      <></>
    );
  }
  useEffect(() => {
    if (tabActive === 9) {
      const newActiveBudget = budgetList?.find(
        (el) => el.status === "NEGOTIATE"
      );
      if (newActiveBudget) {
        setNegotiationVersion(1);
        setDetailedBudget(newActiveBudget._id + "-version-" + 1);
        return activeBudgetDispatch({
          type: "NEW_ACTIVE_BUDGET",
          payload: newActiveBudget,
        });
      }
    }
    if (tabActive === 8) {
      const newActiveBudget = budgetList?.find((el) => el.status === "OPEN");
      if (newActiveBudget) {
        setNegotiationVersion(-1);
        setDetailedBudget(newActiveBudget._id);
        return activeBudgetDispatch({
          type: "NEW_ACTIVE_BUDGET",
          payload: newActiveBudget,
        });
      }
    }
  }, [tabActive]);
  useEffect(() => {
    if (activeBudget._id) {
      handleChangeDetailedBudget(activeBudget._id);
    }
  }, [activeBudget._id]);

  const [selectedBudgets, setSelectedBudgets] = useState<any[]>([]);
  const [selectedNegotiations, setSelectedNegotiations] = useState<any[]>([]);
  const allBudgetsSelected = budgetList?.length === selectedBudgets.length;

  const handleCheckBudget = (budgetId: string | undefined) => {
    if (selectedBudgets.includes(budgetId)) {
      const newBudgets = selectedBudgets.filter((item) => item !== budgetId);
      setSelectedBudgets(newBudgets);
    } else {
      setSelectedBudgets([...selectedBudgets, budgetId]);
    }
  };

  const handleCheckAllBudgets = () => {
    if (allBudgetsSelected) {
      setSelectedBudgets([]);
    } else {
      const allBudgets = budgetList?.map((item) => item._id);
      if (allBudgets) setSelectedBudgets(allBudgets);
    }
  };

  const handleExportSelected = () => {
    if (!selectedBudgets.length) return;

    const budgets = selectedBudgets.join(",");
    const exportTypeQuery = exportType === ExportType.MM ? "mm" : "client";
    const isClosing = tabActive === 10;

    if (exportFormat === ExportFormat.XLX) {
      exportBudget(budgets);
    } else {
      if (allBudgetsSelected) {
        window.open(
          `/export?type=${exportTypeQuery}&projectId=${project}&status=${
            isClosing ? "APROVED" : "BUDGET"
          }`,
          "_blank"
        );
      } else {
        window.open(
          `/export?type=${exportTypeQuery}&projectId=${project}&budgets=${budgets}&status=${
            isClosing ? "APROVED" : "BUDGET"
          }`,
          "_blank"
        );
      }
    }
  };

  const handleCheckNegotiation = (budgetIndex: number) => {
    if (selectedNegotiations[budgetIndex]?.selected) {
      const newItems = selectedNegotiations.map((item, index) => {
        if (index === budgetIndex) {
          return {
            ...item,
            selected: false,
          };
        } else {
          return item;
        }
      });
      setSelectedNegotiations(newItems);
    } else {
      const newItems = selectedNegotiations.map((item, index) => {
        if (index === budgetIndex) {
          return {
            ...item,
            selected: true,
          };
        } else {
          return item;
        }
      });
      setSelectedNegotiations(newItems);
    }
  };

  const handleCheckAllNegotiation = () => {
    const newItems = selectedNegotiations.map((item) => ({
      ...item,
      selected: selectedNegotiations.every((item) => item.selected === true)
        ? false
        : true,
    }));
    setSelectedNegotiations(newItems);
  };

  const handleExportNegotiation = () => {
    if (!selectedNegotiations.some((i) => i.selected)) return;
    const negotiationsExport = selectedNegotiations.filter((i) => i?.selected);

    const grouped = groupByAttributes(negotiationsExport, ["id"]);

    let querysArr: any[] = [];

    Object.entries(grouped).forEach(([key, value]: any) => {
      let arr = [key];
      value.forEach((item: any) => arr.push(item.version));
      querysArr.push(arr.join("-"));
    });

    const finalQuery = querysArr.join(",");

    const exportTypeQuery = exportType === ExportType.MM ? "mm" : "client";
    window.open(
      `/export?type=${exportTypeQuery}&projectId=${project}&status=NEGOTIATE&budgets=${finalQuery}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (!!budgetList?.length) {
      const negotiateList = budgetList?.map((item, index) => {
        if (item.status === "NEGOTIATE") {
          return item;
        } else {
          return null;
        }
      });

      let list: any[] = [];

      negotiateList?.forEach((item, index) => {
        if (item) {
          const versions = item?.negotiationVersion;
          const versionsArr = Array(versions).fill(0);
          versionsArr.forEach((e, index2) => {
            list.push({
              budget: index,
              version: index2,
              id: item._id,
              selected: false,
            });
          });
        }
      });

      setSelectedNegotiations(list);
    }
  }, [budgetList]);

  const buildExportOptions = () => {
    if (tabActive === 9) {
      return (
        <>
          <MenuItem
            style={{
              paddingRight: 16,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              height: 36,
            }}
            onClick={handleCheckAllNegotiation}
          >
            <Checkbox
              checked={selectedNegotiations.every((item) => item?.selected)}
              onClick={handleCheckAllNegotiation}
            />
            <span>Todos</span>
          </MenuItem>

          {selectedNegotiations.map((item, index) => {
            return (
              <MenuItem onClick={() => handleCheckNegotiation(index)}>
                <div
                  style={{
                    paddingRight: 16,
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    height: 20,
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox checked={item?.selected} />
                  <span>
                    Orçamento {item?.budget + 1} - Negociação{" "}
                    {item?.version + 1}
                  </span>
                </div>
              </MenuItem>
            );
          })}

          <MenuItem>
            <Button
              sx={{
                fontSize: "14px",
                width: "100%",
              }}
              onClick={handleExportNegotiation}
            >
              Confirmar
            </Button>
          </MenuItem>
        </>
      );
    } else if (tabActive === 10) {
      return (
        <div>
          <MenuItem
            style={{
              paddingRight: 16,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              height: 36,
            }}
            onClick={handleCheckAllBudgets}
          >
            <Checkbox
              checked={allBudgetsSelected}
              onClick={handleCheckAllBudgets}
            />
            <span>Todos</span>
          </MenuItem>

          {budgetList?.map((budget, index) => {
            if (budget.status === "APROVED")
              return (
                <MenuItem>
                  <div
                    style={{
                      paddingRight: 16,
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                      height: 20,
                      justifyContent: "flex-start",
                    }}
                    onClick={() => handleCheckBudget(budget._id)}
                  >
                    <Checkbox
                      checked={selectedBudgets.includes(budget._id)}
                      onChange={(e) => handleCheckBudget(budget._id)}
                    />
                    <span>Orçamento {index + 1}</span>
                  </div>
                </MenuItem>
              );
          })}

          <MenuItem>
            <Button
              sx={{
                fontSize: "14px",
                width: "100%",
              }}
              onClick={handleExportSelected}
            >
              Confirmar
            </Button>
          </MenuItem>
        </div>
      );
    } else {
      return (
        <div>
          <MenuItem
            style={{
              paddingRight: 16,
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              height: 36,
            }}
            onClick={handleCheckAllBudgets}
          >
            <Checkbox
              checked={allBudgetsSelected}
              onClick={handleCheckAllBudgets}
            />
            <span>Todos</span>
          </MenuItem>

          {budgetList?.map((budget, index) => {
            if (budget.status === "DELETED" || budget.status === "DENIED")
              return <></>;
            else
              return (
                <MenuItem>
                  <div
                    style={{
                      paddingRight: 16,
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                      height: 20,
                      justifyContent: "flex-start",
                    }}
                    onClick={() => handleCheckBudget(budget._id)}
                  >
                    <Checkbox
                      checked={selectedBudgets.includes(budget._id)}
                      onChange={(e) => handleCheckBudget(budget._id)}
                    />
                    <span>Orçamento {index + 1}</span>
                  </div>
                </MenuItem>
              );
          })}

          <MenuItem>
            <Button
              sx={{
                fontSize: "14px",
                width: "100%",
              }}
              onClick={handleExportSelected}
            >
              Confirmar
            </Button>
          </MenuItem>
        </div>
      );
    }
  };

  return (
    <Box p={2} zIndex={999} bgcolor={"#FFF"}>
      <>
        <span style={{ fontSize: "20px", marginLeft: "10px" }}>
          Lista de Orçamentos
        </span>
        <Box
          my={2}
          overflow={"auto"}
          sx={{
            backgroundColor: "#F9FCFF",
          }}
        >
          <FormControl fullWidth>
            <RadioGroup.Root
              label=""
              value={detailedBudget}
              onChange={(e) => {
                handleChangeDetailedBudget(e.target.value);
              }}
            >
              {renderBudgetSumaries()}
            </RadioGroup.Root>
          </FormControl>
        </Box>
        <Box
          sx={{ cursor: "pointer" }}
          border={"1px solid #1361A4"}
          borderRadius={1}
          padding={0.5}
          m={1}
          marginLeft={"auto"}
          display={"flex"}
          justifyContent={"center"}
          width={"60%"}
          onClick={createEmptyBudget}
        >
          <span style={{ color: "#1361A4", fontSize: "13px" }}>
            Novo Orçamento
          </span>
        </Box>
        <Box
          p={"6px 10px"}
          border={"#DBDADE 1px solid"}
          borderRadius={1}
          mt={3}
          display={budgetList?.length! > 0 ? "" : "none"}
        >
          <Box pb={1}>
            <Typography variant={"body1"}>
              Exportar {exportActiveLabel[tabActive] || ""}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <FormLabel sx={{ fontSize: 14, mt: 2.5 }}>Taxas</FormLabel>

              <RadioGroup.Root
                label=""
                value={exportType}
                onChange={(e) => setExportType(e.target.value as ExportType)}
              >
                <RadioGroup.Item
                  label={"Cliente (valores)"}
                  value={ExportType.MM}
                />
                <RadioGroup.Item
                  label={"Cliente (descritivo)"}
                  value={ExportType.CLIENT}
                />
              </RadioGroup.Root>
            </FormControl>

            <FormControl>
              <FormLabel sx={{ fontSize: 14, mt: 1.2 }}>Formato</FormLabel>

              <RadioGroup.Root
                label=""
                value={exportFormat}
                onChange={(e) =>
                  setExportFormat(e.target.value as ExportFormat)
                }
              >
                <RadioGroup.Item label={"PDF"} value={ExportFormat.PDF} />
                {exportType !== ExportType.CLIENT && (
                  <RadioGroup.Item label={"XLX"} value={ExportFormat.XLX} />
                )}
              </RadioGroup.Root>
            </FormControl>
          </Box>

          <Button
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<VscChevronDown />}
            sx={{
              fontSize: "14px",
              width: "100%",
              mt: 2,
              mb: 1,
              height: 36,
            }}
            variant="outlined"
          >
            Exportar
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {buildExportOptions()}
          </Menu>
        </Box>
      </>
    </Box>
  );
};

export default RightSideBar;
